import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Input, RadioGroup } from '../../../flexyui';
import { DiscountFields } from '../../../../types/coupon-data-types';
import SelectedProducts from '../../selected-products/selected-products';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { InfoCardApplication } from '../../../../constants/coupons';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';
import SelectedCollections from '../../selected-collections/selected-collections';
import { ProductSelectionDialog } from '../../product-selection/product-selection';
import { CollectionSelectionDialog } from '../../collection-selection/collection-selection';

export const DiscountRequirement = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  const [purchaseSelectionSearch, setPurchaseSelectionSearch] = useState('');
  const [openPurchaseProductSelection, setOpenPurchaseProductSelection] = useState<boolean>(false);
  const [openPurchaseCollectionSelection, setOpenPurchaseCollectionSelection] = useState<boolean>(false);

  useEffect(() => {
    if (values?.purchase_requirement?.productType === 'PURCHASE_PRODUCTS' && purchaseSelectionSearch.length > 0) {
      setOpenPurchaseProductSelection(true);
    } else if (
      values?.purchase_requirement?.productType === 'PURCHASE_COLLECTIONS' &&
      purchaseSelectionSearch.length > 0
    ) {
      setOpenPurchaseCollectionSelection(true);
    } else {
      setOpenPurchaseProductSelection(false);
      setOpenPurchaseCollectionSelection(false);
    }
  }, [purchaseSelectionSearch]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
      <DiscountInfoCard data={InfoCardApplication[1]} />

      <div>
        <div className="font-semibold text-base mb-4 block sm:hidden">Are there any purchase requirements?</div>
        <RadioGroup {...getFieldProps('purchase_requirement.type')} defaultValue="NONE">
          <RadioWithLabel
            label="No purchase requirements"
            value="NONE"
            onClick={() => setFieldValue('purchase_requirement.type', 'NONE')}
          />
          <RadioWithLabel
            label="Minimum/Maximum purchase amount"
            value="PURCHASE_AMOUNT"
            onClick={() => setFieldValue('purchase_requirement.type', 'PURCHASE_AMOUNT')}
          />
          <RadioWithLabel
            label="Minimum/Maximum quantity of items"
            value="PURCHASE_QUANTITY"
            onClick={() => setFieldValue('purchase_requirement.type', 'PURCHASE_QUANTITY')}
          />
        </RadioGroup>
        {values?.purchase_requirement?.type === 'PURCHASE_AMOUNT' && (
          <>
            <div className="flex gap-4 mt-8">
              <div className={'w-[150px]'}>
                <div className="font-medium mb-1">Minimum amount</div>
                <Input
                  {...getFieldProps('purchase_requirement.min_amount')}
                  type="number"
                  iconPosition="start"
                  icon={<Unicons.UilRupeeSign size={15} />}
                  error={touched.purchase_requirement?.min_amount && !!errors.purchase_requirement?.min_amount}
                  errorMessage={errors.purchase_requirement?.min_amount}
                />
              </div>
              {values?.purchase_requirement?.show_max_amount && (
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Maximum amount</div>
                  <Input
                    {...getFieldProps('purchase_requirement.max_amount')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.purchase_requirement?.max_amount && !!errors.purchase_requirement?.max_amount}
                    errorMessage={errors.purchase_requirement?.max_amount}
                  />
                </div>
              )}
            </div>
            <CheckboxWithLabel
              label="Maximum purchase amount cap"
              value="purchase_requirement.show_max_amount"
              checked={values.purchase_requirement.show_max_amount}
              onClick={() =>
                setFieldValue('purchase_requirement.show_max_amount', !values.purchase_requirement.show_max_amount)
              }
            />
          </>
        )}

        {values?.purchase_requirement?.type === 'PURCHASE_QUANTITY' && (
          <>
            <div className="flex gap-4 mt-8">
              <div className={'w-[150px]'}>
                <div className="font-medium mb-1">Minimum Quantity</div>
                <Input
                  {...getFieldProps('purchase_requirement.min_quantity')}
                  type="number"
                  error={touched.purchase_requirement?.min_quantity && !!errors.purchase_requirement?.min_quantity}
                  errorMessage={errors.purchase_requirement?.min_quantity}
                />
              </div>
              {values?.purchase_requirement?.show_max_quantity && (
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Maximum Quantity</div>
                  <Input
                    {...getFieldProps('purchase_requirement.max_quantity')}
                    type="number"
                    error={touched.purchase_requirement?.max_quantity && !!errors.purchase_requirement?.max_quantity}
                    errorMessage={errors.purchase_requirement?.max_quantity}
                  />
                </div>
              )}
            </div>
            <CheckboxWithLabel
              label="Maximum quantity cap"
              value="purchase_requirement.show_max_quantity"
              checked={values.purchase_requirement.show_max_quantity}
              onClick={() =>
                setFieldValue('purchase_requirement.show_max_quantity', !values.purchase_requirement.show_max_quantity)
              }
            />
          </>
        )}
        <div className={'mt-4'}>
          {(values?.purchase_requirement?.type === 'PURCHASE_QUANTITY' ||
            values?.purchase_requirement?.type === 'PURCHASE_AMOUNT') && (
            <>
              <RadioGroup {...getFieldProps('purchase_requirement.productType')} defaultValue="PURCHASE_CART">
                <RadioWithLabel
                  label="For entire cart"
                  value="PURCHASE_CART"
                  onClick={() => setFieldValue('purchase_requirement.productType', 'PURCHASE_CART')}
                />
                <RadioWithLabel
                  label="For specific products"
                  value="PURCHASE_PRODUCTS"
                  onClick={() => setFieldValue('purchase_requirement.productType', 'PURCHASE_PRODUCTS')}
                />
                <RadioWithLabel
                  label="For specific collections"
                  value="PURCHASE_COLLECTIONS"
                  onClick={() => setFieldValue('purchase_requirement.productType', 'PURCHASE_COLLECTIONS')}
                />
              </RadioGroup>
              {values?.purchase_requirement?.productType !== 'PURCHASE_CART' && (
                <div>
                  <div className="flex mt-4 gap-2">
                    <div className="flex-1">
                      <Input
                        type="text"
                        placeholder="Search"
                        iconPosition="start"
                        icon={<Unicons.UilSearch size={18} />}
                        value={purchaseSelectionSearch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          setPurchaseSelectionSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-fit"
                        onClick={() => {
                          if (values.purchase_requirement?.productType === 'PURCHASE_PRODUCTS') {
                            setOpenPurchaseProductSelection(true);
                          } else if (values.purchase_requirement?.productType === 'PURCHASE_COLLECTIONS') {
                            setOpenPurchaseCollectionSelection(true);
                          }
                        }}
                      >
                        Browse
                      </Button>
                      <ProductSelectionDialog
                        key={'PurchaseProductSelection'}
                        open={
                          values.purchase_requirement?.productType === 'PURCHASE_PRODUCTS' &&
                          openPurchaseProductSelection
                        }
                        setOpen={(val: boolean) => {
                          setOpenPurchaseProductSelection(val);
                          setPurchaseSelectionSearch('');
                        }}
                        search={purchaseSelectionSearch}
                        preSelections={values.purchase_requirement.products}
                        onSave={(value: any) => {
                          setFieldValue('purchase_requirement.products', value);
                        }}
                      />
                      <CollectionSelectionDialog
                        key={'PurchaseCollectionSelection'}
                        open={
                          values.purchase_requirement?.productType === 'PURCHASE_COLLECTIONS' &&
                          openPurchaseCollectionSelection
                        }
                        setOpen={(val: boolean) => {
                          setOpenPurchaseCollectionSelection(val);
                          setPurchaseSelectionSearch('');
                        }}
                        search={purchaseSelectionSearch}
                        preSelections={values.purchase_requirement.collections}
                        onSave={(value: any) => {
                          setFieldValue('purchase_requirement.collections', value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    {values.purchase_requirement.productType === 'PURCHASE_PRODUCTS' && (
                      <SelectedProducts
                        on="purchase_requirement.products"
                        setFieldValue={setFieldValue}
                        value={values.purchase_requirement.products}
                      />
                    )}
                    {values.purchase_requirement.productType === 'PURCHASE_COLLECTIONS' && (
                      <SelectedCollections
                        on="purchase_requirement.collections"
                        setFieldValue={setFieldValue}
                        value={values.purchase_requirement.collections}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
