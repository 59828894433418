import React, { FC } from 'react';
import { Button } from '../../flexyui/Button/index';
import * as Unicons from '@iconscout/react-unicons';

type activeButtonProps = {
  step: string;
  logo: any;
  activeStep: number;
  stepNumber: number;
  setActiveStep: (data: any) => void;
  disabled: boolean;
  totalActiveStepIndex?: number;
};

export const ActiveButton: FC<activeButtonProps> = ({
  step,
  logo,
  activeStep,
  stepNumber,
  setActiveStep,
  disabled,
  totalActiveStepIndex = 3
}) => {
  return (
    <>
      <Button
        variant="outline"
        className={`rounded-full !w-fit text-sm ${
          stepNumber === activeStep
            ? 'text-[#2F72FF] bg-[#F1F6FF]'
            : stepNumber < activeStep
              ? 'text-[#595F74] bg-white'
              : 'text-[#888D9B] bg-white'
        } border-[1.5px] ${
          stepNumber === activeStep
            ? 'border-[#2F72FF]'
            : stepNumber < activeStep
              ? 'border-[#595F74]'
              : 'border-[rgba(18,27,56,0.20)]'
        } hover:border-[1.5px] hover:border-[#2F72FF] hover:bg-gray-50`}
        onClick={() => {
          if (!disabled && stepNumber < activeStep) {
            setActiveStep(stepNumber);
          }
        }}
        data-coupon-step-number={stepNumber}
      >
        <div className="flex gap-2 items-center">
          {logo}
          <span className="whitespace-nowrap">{step.toUpperCase()}</span>
        </div>
      </Button>
      {stepNumber < totalActiveStepIndex && (
        <Unicons.UilAngleRight
          size={24}
          color={stepNumber < activeStep ? '#595F74' : 'rgba(18, 27, 56, 0.20)'}
          className="min-w-6"
        />
      )}
    </>
  );
};
