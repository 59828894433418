import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Input, RadioGroup } from '../../../flexyui';
import { ApplicationTarget, CouponType } from '../../../../graphql';
import { DiscountFields } from '../../../../types/coupon-data-types';
import SelectedProducts from '../../selected-products/selected-products';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import SelectedCollections from '../../selected-collections/selected-collections';
import { ProductSelectionDialog } from '../../product-selection/product-selection';
import { InfoCardSetup, InfoCardApplication } from '../../../../constants/coupons';
import { CollectionSelectionDialog } from '../../collection-selection/collection-selection';

export const DiscountApplyType = () => {
  const { values, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  const [selectionSearch, setSelectionSearch] = useState('');
  const [openProductSelection, setOpenProductSelection] = useState<boolean>(false);
  const [openCollectionSelection, setOpenCollectionSelection] = useState<boolean>(false);

  useEffect(() => {
    if (values?.application?.type === ApplicationTarget.Products && selectionSearch.length > 0) {
      setOpenProductSelection(true);
    } else if (values?.application?.type === ApplicationTarget.Collections && selectionSearch.length > 0) {
      setOpenCollectionSelection(true);
    } else {
      setOpenProductSelection(false);
      setOpenCollectionSelection(false);
    }
  }, [selectionSearch]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardSetup[1]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">What should this discount do?</div>
          <RadioGroup {...getFieldProps('coupon_type')} defaultValue={CouponType.CartAmount}>
            <RadioWithLabel
              label="Amount off order"
              value={CouponType.CartAmount}
              onClick={() => setFieldValue('coupon_type', CouponType.CartAmount)}
            />
            <RadioWithLabel
              label="Amount off products"
              value={CouponType.ProductAmount}
              onClick={() => setFieldValue('coupon_type', CouponType.ProductAmount)}
            />
            {/* <RadioWithLabel*/}
            {/*  label="Buy X get Y"*/}
            {/*  value={CouponType.Bxgy}*/}
            {/*  onClick={() => setFieldValue('coupon_type', CouponType.Bxgy)}*/}
            {/* />*/}
            {/* <RadioWithLabel*/}
            {/*  label="Purchase Driven"*/}
            {/*  value={CouponType.PurchaseHistory}*/}
            {/*  onClick={() => setFieldValue('coupon_type', CouponType.PurchaseHistory)}*/}
            {/* />*/}
          </RadioGroup>
        </div>
      </div>

      {values?.coupon_type === CouponType.ProductAmount && (
        <>
          <hr />
          <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
            <DiscountInfoCard data={InfoCardApplication[0]} />

            <div>
              <div className="font-semibold text-base mb-4 block sm:hidden">What does the discount applies to?</div>
              <RadioGroup {...getFieldProps('application.type')} defaultValue={ApplicationTarget.Universal}>
                {/* <RadioWithLabel*/}
                {/*  label="Total order value"*/}
                {/*  value={ApplicationTarget.Universal}*/}
                {/*  onClick={() => setFieldValue('application.type', ApplicationTarget.Universal)}*/}
                {/* />*/}
                <RadioWithLabel
                  label="Specific products"
                  value={ApplicationTarget.Products}
                  onClick={() => setFieldValue('application.type', ApplicationTarget.Products)}
                />
                <RadioWithLabel
                  label="Specific collections"
                  value={ApplicationTarget.Collections}
                  onClick={() => setFieldValue('application.type', ApplicationTarget.Collections)}
                />
              </RadioGroup>
              {values?.application?.type !== ApplicationTarget.Universal && (
                <div>
                  <div className="flex mt-4 gap-2">
                    <div className="flex-1">
                      <Input
                        type="text"
                        placeholder="Search"
                        iconPosition="start"
                        icon={<Unicons.UilSearch size={18} />}
                        value={selectionSearch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          setSelectionSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-fit"
                        onClick={() => {
                          if (values.application.type === ApplicationTarget.Products) {
                            setOpenProductSelection(true);
                          } else if (values.application.type === ApplicationTarget.Collections) {
                            setOpenCollectionSelection(true);
                          }
                        }}
                      >
                        Browse
                      </Button>
                      <ProductSelectionDialog
                        key={'ProductSelection'}
                        open={values.application.type === ApplicationTarget.Products && openProductSelection}
                        setOpen={(val: boolean) => {
                          setOpenProductSelection(val);
                          setSelectionSearch('');
                        }}
                        search={selectionSearch}
                        preSelections={values.application.products}
                        onSave={(value: any) => {
                          setFieldValue('application.products', value);
                        }}
                      />
                      <CollectionSelectionDialog
                        key={'CollectionSelection'}
                        open={values.application.type === ApplicationTarget.Collections && openCollectionSelection}
                        setOpen={(val: boolean) => {
                          setOpenCollectionSelection(val);
                          setSelectionSearch('');
                        }}
                        search={selectionSearch}
                        preSelections={values.application.collections}
                        onSave={(value: any) => {
                          setFieldValue('application.collections', value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    {values.application.type === ApplicationTarget.Products && (
                      <SelectedProducts
                        on="application.products"
                        setFieldValue={setFieldValue}
                        value={values.application.products}
                      />
                    )}
                    {values.application.type === ApplicationTarget.Collections && (
                      <SelectedCollections
                        on="application.collections"
                        setFieldValue={setFieldValue}
                        value={values.application.collections}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
