import React, { useEffect, useState } from 'react';
import { Card, Tabs, TabsContent, TabsList, TabsTrigger } from '../flexyui';
import { AddressTab, GeneralTab, LoginTab, PaymentsTab } from './customization-tabs';
import useSaveCancelButtons from '../../../src/hooks/use-save-cancel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../src/store';
import { setConfirmationDialog, setRouteLink } from '../../../src/store/main-slice';
import { useLocation, useNavigate } from 'react-router-dom';

export const CustomizationLeftPanel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { showActions } = useSaveCancelButtons();
  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    const path = location.pathname.replace('/customization/', '');
    const isValidPath = ['general', 'login', 'address', 'payments'].includes(path);

    if (isValidPath) {
      setActiveTab(path);
    } else {
      setActiveTab('general');
      navigate('/customization/general', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (value: string) => {
    dispatch(setRouteLink(`/customization/${value}`));

    if (showActions) {
      dispatch(setConfirmationDialog(true));
    } else {
      setActiveTab(value);
      navigate(`/customization/${value}`, { replace: true });
    }
  };

  return (
    <div>
      <Tabs value={activeTab} className="justify-center" onValueChange={handleTabChange}>
        <TabsList className="max-w-[500px] justify-between backdrop-blur-3xl">
          <TabsTrigger value="general" className="px-4">
            General
          </TabsTrigger>
          <TabsTrigger value="login" className="px-4">
            Login
          </TabsTrigger>
          <TabsTrigger value="address" className="px-4">
            Address
          </TabsTrigger>
          <TabsTrigger value="payments" className="px-4">
            Payments
          </TabsTrigger>
        </TabsList>
        <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] mt-3 mb-4 pt-3 pb-3 sm:pb-5">
          <div className="px-3 sm:px-5">
            <TabsContent value="general">
              <GeneralTab />
            </TabsContent>
            <TabsContent value="login">
              <LoginTab />
            </TabsContent>
            <TabsContent value="address">
              <AddressTab />
            </TabsContent>
            <TabsContent value="payments">
              <PaymentsTab />
            </TabsContent>
          </div>
        </Card>
      </Tabs>
    </div>
  );
};
