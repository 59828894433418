import React from 'react';
import { DiscountCode } from '../../shared/discount/discount-setup/discount-code';
import { DiscountApplyType } from '../../shared/discount/discount-setup/discount-apply-type';
import { DiscountOff } from '../../shared/discount/discount-setup/discount-off';

export const DiscountAmountSetup = () => {
  return (
    <>
      <DiscountCode />
      <hr />
      <DiscountApplyType />
      <hr />
      <DiscountOff />
    </>
  );
};
