import { CouponType } from '../../graphql';

export const getDiscountPageKeys = (couponType: CouponType): string[][] => {
  const commonPageKeys = [
    ['code', 'title'],
    ['purchase_requirement'],
    ['user_order_requirement'],
    ['activation_period', 'per_customer_usage_limit', 'total_usage_limit']
  ];

  switch (couponType) {
    case CouponType.Amount:
    case CouponType.CartAmount:
    case CouponType.ProductAmount:
      return [[...commonPageKeys[0], 'discount_amount', 'discount_max_cap', 'application'], ...commonPageKeys.slice(1)];

    case CouponType.Bxgy:
      return [commonPageKeys[0], ['application', ...commonPageKeys[1]], ...commonPageKeys.slice(2)];

    case CouponType.PurchaseHistory:
      return [
        [...commonPageKeys[0], 'discount_amount', 'discount_max_cap'],
        ['purchase_activity', 'purchase_activity_period'],
        ...commonPageKeys.slice(1)
      ];

    case CouponType.Payment:
      return [
        [...commonPageKeys[0], 'discount_amount', 'discount_max_cap', 'apply_discount_to', 'payment_offers'],
        ...commonPageKeys.slice(1)
      ];

    default:
      return commonPageKeys;
  }
};
