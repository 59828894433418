import * as Yup from 'yup';
import { DiscountSchema, PurchaseRequirementSchema } from './index';
import { DiscountType } from '../../../graphql';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';
import { ItemsFrom } from '../../../constants/coupons';

export const DiscountBxgy = DiscountSchema.shape({
  application: Yup.object().shape({
    items_from: Yup.string().default(ItemsFrom.SPECIFIC_PRODUCTS).required(),
    min_quantity: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .moreThan(0, ErrorMessages.NEGATIVE_ERROR)
      .required(requiredError('Quantity')),
    products: Yup.array()
      .default([])
      .when('items_from', {
        is: ItemsFrom.SPECIFIC_PRODUCTS,
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when('items_from', {
        is: ItemsFrom.SPECIFIC_COLLECTION,
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      }),
    per_customer_usage_limit: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('show_max_per_order', {
        is: true,
        then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Max uses')),
        otherwise: (schema) => schema.nullable()
      }),
    show_max_per_order: Yup.boolean().default(false),
    discount_type: Yup.string().default(DiscountType.Percentage).required(),
    discount_amount: Yup.number()
      .required(requiredError('Discount value'))
      .min(0, ErrorMessages.NEGATIVE_ERROR)
      .when('discount_type', {
        is: DiscountType.Percentage,
        then: (schema) => schema.max(100, ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR)
      })
  }),
  purchase_requirement: PurchaseRequirementSchema.shape({
    items_from: Yup.string().default(ItemsFrom.SPECIFIC_PRODUCTS),
    products: Yup.array()
      .default([])
      .when('items_from', {
        is: ItemsFrom.SPECIFIC_PRODUCTS,
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when('items_from', {
        is: ItemsFrom.SPECIFIC_COLLECTION,
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      })
  })
});
