export const getProductSelection = (products: any) => {
  let variants: any = [];

  if (products?.length > 0) {
    if (typeof products[0] !== 'string' && typeof products[0] !== 'number') {
      for (let i = 0; i < products.length; i += 1) {
        variants = [
          ...variants,
          ...products[i].variants.map((item: any) => {
            return { product_id: products[i].product_id, variant_id: item.variant_id };
          })
        ];
      }
    }
  }

  return variants;
};

export const getCollectionSelection = (collections: any) => {
  const collectionList: any = [];
  if (collections?.length > 0) {
    if (typeof collections[0] !== 'string' && typeof collections[0] !== 'number') {
      for (let i = 0; i < collections.length; i += 1) {
        collectionList.push({ collection_id: collections[i].collection_id });
      }
    }
  }
  return collectionList;
};
