import React, { FC } from 'react';
import { CouponType } from '../../../graphql';
import { addCouponsData } from '../../../constants/coupons';
import { Chip, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, Label } from '../../flexyui';

type props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  openDiscountDialog: (value: CouponType) => void;
};

export const AddCouponDialog: FC<props> = ({ open, setOpen, openDiscountDialog }) => {
  return (
    <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
      <DialogContent className="!gap-0" size="sm" close={true}>
        <div>
          <DialogHeader>
            <DialogTitle>
              <p>Create Coupon</p>
            </DialogTitle>
            <DialogDescription className="!p-0 !m-0">
              <div className="text-[#121b38] mb-0.5">
                {addCouponsData.map((each, index) => (
                  <div
                    key={each.id}
                    className={`flex items-center justify-between py-2 px-4 !cursor-pointer ${
                      index !== addCouponsData.length - 1 && 'border-b'
                    }`}
                    onClick={() => {
                      openDiscountDialog(each.id);
                      setOpen(false);
                    }}
                  >
                    <div className="flex flex-col">
                      <Label size="sm" className="text-base font-medium cursor-pointer">
                        {each.title}
                      </Label>
                      <Label className="text-[#888D9B] cursor-pointer" size="sm">
                        {each.desc}
                      </Label>
                    </div>
                    {each?.isNew && <Chip text="NEW" variant="success" />}
                  </div>
                ))}
              </div>
            </DialogDescription>
          </DialogHeader>
        </div>
      </DialogContent>
    </Dialog>
  );
};
