import React, { FC, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  Button
} from '../flexyui';
import { FormikErrors } from 'formik';
import { CouponType } from '../../graphql';
import * as Unicons from '@iconscout/react-unicons';
import { addCouponsData } from '../../constants/coupons';
import { discountHeader } from '../../constants/discount';
import { DiscountFields } from '../../types/coupon-data-types';
import { ActiveButton } from '../shared/active-button/active-button';
import { getDiscountPageKeys } from '../../utils/discount/discount-page-keys';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { CreateDiscountPreview } from '../coupons/coupons-preview/coupons-preview';
import { CreateOfferPreview } from '../payment-offers/offers-preview/offer-preview';

type props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
  type: CouponType;
  errors: any;
  discountDetails: any;
  setDiscountDetails: (value: any) => void;
  totalActiveStepIndex?: number;
  content: React.ReactNode;
  isSubmitting: boolean;
  resetForm: any;
  submitForm: any;
};

export const DiscountDialog: FC<props> = ({
  open,
  setOpen,
  activeStep,
  setActiveStep,
  type,
  errors,
  discountDetails,
  setDiscountDetails,
  totalActiveStepIndex = 3,
  content,
  isSubmitting,
  resetForm,
  submitForm
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const checkErrorForNavigation = (errors: FormikErrors<DiscountFields>) =>
    getDiscountPageKeys(type)[activeStep].some((key) => Object.keys(errors).includes(key));

  const couponTitle =
    type === CouponType.Payment
      ? 'Payment Offer'
      : addCouponsData.find((coupon) => coupon.id === type)?.title + ' Discount';

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector(`[data-coupon-step-number="${activeStep}"]`);
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [activeStep]);

  return (
    <AlertDialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <AlertDialogContent className="!gap-0" size="fullWidth">
        <AlertDialogHeader className="w-full h-full">
          <AlertDialogTitle className="p-4 sm:pl-6 sm:pr-5 bg-[#F9F9F9]">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Unicons.UilPercentage />
                <h1 className="text-xl font-medium hidden sm:block">
                  {discountDetails ? `Edit ${couponTitle}` : `Create ${couponTitle}`}
                </h1>
                <h1 className="text-xl font-medium block sm:hidden">
                  {discountDetails ? 'Edit Discount' : 'Create Discount'}
                </h1>
              </div>
              <Button
                size="icon"
                variant="icon"
                onClick={() => {
                  resetForm();
                  setActiveStep(0);
                  setOpen(false);
                  setDiscountDetails(null);
                }}
                className="hover:bg-[#f2f8ff]"
              >
                <Unicons.UilTimes className="text-[#2F72FF]" />
              </Button>
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription className="!p-0 !m-0 w-full !h-[calc(100dvh-65px)] overflow-hidden text-[#2A324C]">
            <div className="flex h-full">
              <div className="flex-1 relative">
                <div className="border-r-[1px] h-full">
                  <div className="w-[100vw] sm:w-[calc(100vw-22rem)] sticky px-4 sm:px-8">
                    <div
                      className="flex items-center gap-2 overflow-x-auto scrollbar-hide py-3 sm:py-4"
                      ref={scrollContainerRef}
                    >
                      {(totalActiveStepIndex === 3
                        ? discountHeader.filter((header) => header.step !== 'User Behaviour')
                        : discountHeader
                      ).map((header, index) => {
                        return (
                          <ActiveButton
                            key={header.step}
                            step={header.step}
                            logo={header.logo}
                            activeStep={activeStep}
                            stepNumber={index}
                            setActiveStep={setActiveStep}
                            disabled={Object.keys(errors).length > 0}
                            totalActiveStepIndex={totalActiveStepIndex}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <hr />

                  <div className="h-[calc(100dvh-180px)] sm:h-[calc(100dvh-190px)] mb-2 overflow-auto">{content}</div>
                </div>
                <div className="px-4 max-h-[55px] flex justify-end gap-2 absolute bg-[#F9F9F9] bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 w-full transition duration-150 ease-out hover:ease-in z-10">
                  {activeStep !== 0 && (
                    <Button variant="backward" className={'h-8'} onClick={() => setActiveStep(activeStep - 1)}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="forward"
                    className={'h-8'}
                    onClick={async () => {
                      if (activeStep !== totalActiveStepIndex) setActiveStep(activeStep + 1);
                      else {
                        await submitForm();
                        resetForm();
                      }
                    }}
                    disabled={checkErrorForNavigation(errors) || isSubmitting}
                  >
                    {/* {createCouponLoading || updateCouponLoading ? (*/}
                    {isSubmitting ? (
                      <LoadingIcon height={20} className={'animate-spin text-white'} />
                    ) : activeStep === 4 ? (
                      discountDetails ? (
                        'Save'
                      ) : (
                        'Submit'
                      )
                    ) : (
                      'Next'
                    )}
                  </Button>
                </div>
              </div>
              <div className="p-4 hidden sm:block">
                {type === CouponType.Payment ? (
                  <CreateOfferPreview activeStep={activeStep} selectedPaymentOffer={null} isEdit={open} />
                ) : (
                  <CreateDiscountPreview activeStep={activeStep} selectedCoupon={null} isEdit={open} />
                )}
              </div>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};
