import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { useFacebookAnalyticsConfigQuery, useUpdateFacebookAnalyticsConfigMutation } from '../../../../graphql';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  Card,
  Checkbox,
  Chip,
  Input,
  Label,
  Skeleton,
  Switch
} from '../../../flexyui';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import { useNavigate } from 'react-router-dom';

export const FacebookIntegrations: React.FC = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTurnOn, setShowTurnOn] = useState<boolean>(false);
  const [facebookEnabled, setFacebookEnabled] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    pixelID: '',
    enabled: true,
    conversionApi: '',
    conversionApiEnabled: false
  });

  const { data: integrationData, loading, refetch } = useFacebookAnalyticsConfigQuery();
  const [updateFacebook, { loading: facebookLoading }] = useUpdateFacebookAnalyticsConfigMutation();

  const handleFormSubmitButtonClick = async () => {
    setOpenModal(false);
    await updateFacebook({
      variables: {
        configs: {
          enabled: facebookEnabled,
          pixels: [
            {
              enabled: credentials.enabled,
              pixel_id: credentials?.pixelID?.trim(),
              conversion_api_token: credentials?.conversionApi,
              conversion_api_enabled: credentials?.conversionApiEnabled
            }
          ]
        }
      }
    });
    await refetch();
  };

  useEffect(() => {
    if (integrationData) {
      setCredentials({
        pixelID: integrationData?.analyticsConfig.facebook.pixels[0]?.pixel_id || '',
        enabled: integrationData?.analyticsConfig.facebook.pixels[0]?.enabled ?? true,
        conversionApi: integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_token || '',
        conversionApiEnabled: integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_enabled || false
      });
      setFacebookEnabled(true);
    }
  }, [integrationData]);

  const handlePixelId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = { ...credentials, pixelID: e.target.value };
    setCredentials(newValues);
  };

  const handleConversionApiToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newApiToken = e.target.value;
    const newValues = {
      ...credentials,
      conversionApi: newApiToken,
      conversionApiEnabled: newApiToken.trim() !== ''
    };
    setCredentials(newValues);
  };

  const handleConversionApiSwitch = (value: boolean) => {
    const newValues = {
      ...credentials,
      conversionApiEnabled: value
    };
    setCredentials(newValues);
  };

  const handlePixelEnableCheckbox = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await updateFacebook({
      variables: {
        configs: {
          enabled: facebookEnabled,
          pixels: [
            {
              enabled: e.target.checked,
              pixel_id: credentials.pixelID,
              conversion_api_token: credentials?.conversionApi,
              conversion_api_enabled: credentials?.conversionApiEnabled
            }
          ]
        }
      }
    });
    await refetch();
  };

  const handleCancelClick = () => {
    setOpenModal(false);
    setCredentials({
      pixelID: integrationData?.analyticsConfig.facebook.pixels[0]?.pixel_id || '',
      enabled: integrationData?.analyticsConfig.facebook.pixels[0]?.enabled || false,
      conversionApi: integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_token || '',
      conversionApiEnabled: integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_enabled || false
    });
  };

  const handleSaveFacebookConfig = async () => {
    await updateFacebook({
      variables: {
        configs: {
          enabled: facebookEnabled,
          pixels: [
            {
              enabled: credentials.enabled,
              pixel_id: credentials?.pixelID?.trim(),
              conversion_api_token: credentials?.conversionApi,
              conversion_api_enabled: credentials?.conversionApiEnabled
            }
          ]
        }
      }
    });
    await refetch();
  };

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">Meta Pixels</div>
      </div>

      <Card className="p-3 pl-4">
        <div className="flex items-center">
          Enable Pixels
          <div className="mx-2 hidden sm:block">
            {loading ? (
              <Skeleton variant="circular" className="!m-0 w-[70px]" />
            ) : (
              <Chip
                text={integrationData?.analyticsConfig.facebook.enabled ? 'Enabled' : 'Disabled'}
                variant={integrationData?.analyticsConfig.facebook.enabled ? 'success' : 'draft'}
              />
            )}
          </div>
          <div className="flex items-center justify-end flex-1">
            {loading ? (
              <Skeleton className="w-[95px] h-[36px] !m-0" />
            ) : (
              <Button
                size={'md'}
                onClick={async () => {
                  setFacebookEnabled((prev) => !prev);
                  setShowTurnOn(true);
                }}
                variant={integrationData?.analyticsConfig.facebook.enabled ? 'outline' : 'default'}
                disabled={
                  integrationData?.analyticsConfig.facebook.pixels.length === 0 ||
                  !integrationData?.analyticsConfig.facebook.pixels[0]?.pixel_id?.trim()
                }
              >
                {integrationData?.analyticsConfig.facebook.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <Label size={'paragraph'}>Pixels</Label>
        <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
          <div className="add-new-pixel">
            {loading ? (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-5 w-5" />
                    <Skeleton className="w-[150px] !m-0" />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-8 w-8" />
                    <Skeleton className="!m-0 h-8 w-8" />
                  </div>
                </div>
                <div className="mt-4">
                  <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                  <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
                </div>
              </>
            ) : (
              <>
                {integrationData?.analyticsConfig.facebook.pixels.length === 0 ||
                integrationData?.analyticsConfig.facebook.pixels[0]?.pixel_id.trim() === '' ? (
                  <div className="text-center my-4">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <Unicons.UilPlus size={18} className="mr-2" />
                      Add new pixel
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="pixel-id-container my-2">
                      <div className="text-[#888D9B] text-xs">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <Checkbox
                              disabled={facebookLoading}
                              checked={credentials.enabled}
                              id={'pixel_enabled'}
                              name={'pixelEnabled'}
                              onChange={handlePixelEnableCheckbox}
                            />
                            <div className="grid gap-[5px] leading-none ml-3">
                              <Label
                                htmlFor={'pixel_enabled'}
                                size={'paragraph'}
                                className="font-normal text-[#595F74]"
                              >
                                Enable this pixel
                              </Label>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setOpenModal(true);
                              }}
                              className="mr-2"
                            >
                              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
                            </Button>
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="text-[#595F74] hover:text-red-500"
                            >
                              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
                            </Button>
                          </div>
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Pixel ID</div>
                        <div className="flex justify-start text-sm text-[#121B38] font-normal">
                          {integrationData?.analyticsConfig.facebook.pixels[0]?.pixel_id}
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Conversion API Token</div>
                        <div className="max-w-[80%] mt-0.5">
                          <p className="flex justify-start text-sm text-[#121B38] font-normal break-all">
                            {integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_token
                              ? integrationData?.analyticsConfig.facebook.pixels[0]?.conversion_api_token
                              : '-'}
                          </p>
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Conversion API Token</div>
                        <div className="mt-1">
                          <Chip
                            text={credentials.conversionApiEnabled ? 'Enabled' : 'Disabled'}
                            variant={credentials.conversionApiEnabled ? 'success' : 'draft'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Card>

      <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Pixel Data</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="my-2 px-4">
                  <Label size={'paragraph'}>Pixel ID</Label>
                  <Input
                    type="text"
                    name="pixel_id"
                    placeholder="Pixel id"
                    value={credentials.pixelID}
                    onChange={handlePixelId}
                    className="text-[#121b38]"
                  />
                </div>

                <div className="my-4 mt-2 px-4">
                  <Label size={'paragraph'}>Conversions API token</Label>
                  <Input
                    type="text"
                    name="pixel_id"
                    placeholder="Conversions API token"
                    value={credentials.conversionApi}
                    onChange={handleConversionApiToken}
                    error={credentials.conversionApi === '' && credentials.conversionApiEnabled}
                    className="text-[#121b38]"
                  />
                  {credentials.conversionApi === '' && credentials.conversionApiEnabled ? (
                    <p className="text-[10px] text-[#E50000] absolute">
                      {'Please provide conversion api to activate.'}
                    </p>
                  ) : null}
                </div>

                <div className="my-4 mt-2 px-4 flex justify-between items-center">
                  <Label size={'paragraph'}>Conversion API Status</Label>
                  <Switch
                    checked={credentials?.conversionApiEnabled}
                    className="pointer-events-auto mr-1"
                    id={'conversion api status'}
                    onCheckedChange={handleConversionApiSwitch}
                  />
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button variant={'outline'} size={'md'} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              type="submit"
              disabled={
                credentials?.pixelID === '' ||
                credentials?.pixelID === null ||
                credentials?.pixelID === undefined ||
                (credentials.conversionApi === '' && credentials.conversionApiEnabled)
              }
              onClick={() => handleFormSubmitButtonClick()}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={async () => {
          await updateFacebook({
            variables: {
              configs: {
                enabled: false,
                pixels: []
              }
            }
          });
          await refetch();
        }}
        text="This Action will delete the Facebook Pixel"
        confirmActionVariant="destructive"
        loading={facebookLoading}
      />
      <ConfirmationDialog
        showModal={showTurnOn}
        setShowModal={setShowTurnOn}
        onSave={handleSaveFacebookConfig}
        text={`This Action will ${!facebookEnabled ? 'disable' : 'enable'} the Facebook Pixel`}
        loading={facebookLoading}
      />
    </>
  );
};
