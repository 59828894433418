import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { InputTextField } from '../../components/shared';
import { Button, Card, Label } from '../../components/flexyui';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { isValidEmail } from '../../utils/validation';
import { translations } from '../login/i18n';
import { enqueueSnackbar } from 'notistack';

type Props = {
  setForgotPasswordActive: (toggle: boolean) => void | null;
};

export const ForgotPassword: React.FC<Props> = ({ setForgotPasswordActive }) => {
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Reset Password');

  const handleSubmit = async (email: string) => {
    if (isValidEmail(email)) {
      try {
        console.log(email);
        // perform the deed
        setEmail('');
        // assuming all went well
        enqueueSnackbar('Successfully sent password change mail...', {
          variant: 'success'
        });
        setButtonText('Resend Password Reset Mail');
      } catch (e) {
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, email: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(email);
    }
  };

  function handleGotoLoginedClick(): void {
    setForgotPasswordActive(false);
  }

  return (
    <div className="px-4 flex flex-col items-center mt-[133px] h-screen">
      <Logo />
      <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] p-[26px] min-w-[390px] mt-14 autofill:bg-primary">
        <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={async (values) => {
            handleSubmit(values.email);
          }}
        >
          {(props) => (
            <FormikForm>
              <h2 className="text-lg font-semibold mb-3">Recover Account</h2>
              <Label>{translations.EMAIL.value}</Label>
              <div className="mb-4">
                <InputTextField
                  type="email"
                  name="email"
                  placeholder="johndoe@flexype.in"
                  // eslint-disable-next-line max-len
                  handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    handleKeyDown(e, props.values.email)
                  }
                />
              </div>

              <Button type="submit" disabled={props.values.email === ''}>
                {buttonText}
              </Button>
            </FormikForm>
          )}
        </Formik>
        <div
          className="m-auto underline hover:no-underline w-fit mt-4"
          style={{ cursor: 'pointer' }}
          onClick={handleGotoLoginedClick}
        >
          Login?
        </div>
      </Card>
    </div>
  );
};
