import React, { FC } from 'react';
import { Input } from '../../../flexyui';
import { Field, useFormikContext } from 'formik';
import { DateTimePicker } from '../../../flexyui/DateTimePicker';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { OfferFields } from '../../../../types/payment-offers-data-types';
import { InfoCardUsageLimit } from '../../../../constants/coupons';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../../shared/checkbox-with-label/checkbox-with-label';

type props = {
  showCombine?: boolean;
  isPaymentOffer?: boolean;
};

export const DiscountUsageLimit: FC<props> = ({ showCombine = true, isPaymentOffer = false }) => {
  let formikContext;

  if (isPaymentOffer) {
    formikContext = useFormikContext<DiscountFields>();
  } else {
    formikContext = useFormikContext<OfferFields>();
  }

  const { values, touched, errors, getFieldProps, setFieldValue } = formikContext;

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardUsageLimit[0]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Maximum discount usage</div>
          <CheckboxWithLabel
            label="Number of times this discount can be used in total"
            value="show_total_usage_limit"
            checked={values.show_total_usage_limit}
            onClick={() => setFieldValue('show_total_usage_limit', !values.show_total_usage_limit)}
          />
          {values.show_total_usage_limit && (
            <div className={'mt-2 mb-3 w-[150px]'}>
              <Input
                {...getFieldProps('total_usage_limit')}
                type="number"
                error={touched.total_usage_limit && !!errors.total_usage_limit}
                errorMessage={errors.total_usage_limit}
              />
            </div>
          )}
          <CheckboxWithLabel
            label="Number of times this discount can be used per customer"
            value="show_per_customer_usage_limit"
            checked={values.show_per_customer_usage_limit}
            onClick={() => setFieldValue('show_per_customer_usage_limit', !values.show_per_customer_usage_limit)}
          />
          {values.show_per_customer_usage_limit && (
            <div className={'mt-2 mb-3 w-[150px]'}>
              <Input
                {...getFieldProps('per_customer_usage_limit')}
                type="number"
                error={touched.per_customer_usage_limit && !!errors.per_customer_usage_limit}
                errorMessage={errors.per_customer_usage_limit}
              />
            </div>
          )}
        </div>
      </div>

      {showCombine && (
        <>
          <hr />
          <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
            <DiscountInfoCard data={InfoCardUsageLimit[2]} />
            <div>
              <div className="font-semibold text-base mb-4 block sm:hidden">Combine</div>
              <CheckboxWithLabel
                label="Combine with other discounts"
                value="combine"
                checked={values.combine}
                onClick={() => setFieldValue('combine', !values.combine)}
              />
            </div>
          </div>
        </>
      )}

      <hr />
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardUsageLimit[1]} />
        <div>
          <div className="font-semibold text-base block sm:hidden">Discount active period</div>
          <div className="flex flex-col gap-0 xl:flex-row xl:gap-4 mb-6">
            <div className="w-[222px]">
              <div className="font-medium mt-4 mb-1">Start Date & Time</div>
              <Field name="activation_period.start">
                {({ field, form }: any) => {
                  return (
                    <DateTimePicker
                      initialDate={new Date(field.value)}
                      onDateChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      error={!!errors.activation_period?.start}
                      errorMessage={errors.activation_period?.start}
                    />
                  );
                }}
              </Field>
            </div>
            {values.activation_period.has_endTime && (
              <div className="w-[222px]">
                <div className="font-medium mt-4 mb-1">End Date & Time</div>
                <Field name="activation_period.end">
                  {({ field, form }: any) => (
                    <DateTimePicker
                      initialDate={new Date(field.value)}
                      onDateChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      error={!!errors.activation_period?.end}
                      errorMessage={errors.activation_period?.end}
                    />
                  )}
                </Field>
              </div>
            )}
          </div>

          <CheckboxWithLabel
            label="Set end date"
            value="activation_period.has_endTime"
            checked={values.activation_period.has_endTime}
            onClick={() => setFieldValue('activation_period.has_endTime', !values.activation_period.has_endTime)}
          />
        </div>
      </div>
    </>
  );
};
