import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  Input,
  Label,
  Select as SelectCN,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../flexyui';
import { indianStates } from '../../../../constants/settings';
import * as Unicons from '@iconscout/react-unicons';
import { useAddProvinceInTaxDetailMutation, useUpdateProvinceInTaxDetailMutation } from '../../../../graphql';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/loading.svg';

type Props = {
  refetch: any;
  regionOverrideData: any;
  openAddOverrideModal: boolean;
  setOpenAddOverrideModal: (data: boolean) => void;
};

export const RegionOverride: React.FC<Props> = ({
  refetch,
  regionOverrideData,
  openAddOverrideModal,
  setOpenAddOverrideModal
}) => {
  // Filter out the states whose overrides are already present
  const overrideStateCodes = regionOverrideData?.overrideTaxDetail.provinces.map(
    (province: any) => province.state_code
  );
  // eslint-disable-next-line camelcase
  const filteredStates = indianStates.filter(({ state_code }) => !overrideStateCodes?.includes(state_code));

  const getInitialOverrideRate = () => {
    const rate = regionOverrideData?.rate;
    return typeof rate === 'number' ? (rate * 100).toFixed(3).replace(/\.?0+$/, '') : '';
  };

  const [actionType, setActionType] = useState(regionOverrideData?.rate ? 'edit' : 'add');
  const [overrideRate, setOverrideRate] = useState(getInitialOverrideRate());
  const [overrideState, setOverrideState] = useState(
    regionOverrideData?.state_code || filteredStates[0].state_code || 'DEFAULT'
  );

  useEffect(() => {
    if (regionOverrideData) {
      setActionType(regionOverrideData?.rate ? 'edit' : 'add');
      setOverrideRate(getInitialOverrideRate());
      setOverrideState(regionOverrideData?.state_code || filteredStates[0].state_code || 'DEFAULT');
    }
  }, [regionOverrideData]);

  const [addProvinceInTaxDetail, { loading: addLoading }] = useAddProvinceInTaxDetailMutation();
  const [updateProvinceInTaxDetail, { loading: updateLoading }] = useUpdateProvinceInTaxDetailMutation();

  const handleAddProvinceInTaxDetail = async () => {
    const stateFromCode =
      overrideState === 'DEFAULT'
        ? 'All Regions'
        : indianStates.find((state) => state.state_code === overrideState)?.state;
    try {
      await addProvinceInTaxDetail({
        variables: {
          collectionId: regionOverrideData?.overrideTaxDetail?.collection_id,
          countryCode: 'IN',
          input: {
            rate: getRate(),
            state_code: overrideState,
            state: stateFromCode as string
          }
        }
      });

      setOpenAddOverrideModal(false);
      enqueueSnackbar('Hurray! Region override added successfully!', {
        variant: 'success'
      });

      await refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const handleUpdateProvinceInTaxDetail = async () => {
    try {
      await updateProvinceInTaxDetail({
        variables: {
          collectionId: regionOverrideData?.overrideTaxDetail?.collection_id,
          countryCode: 'IN',
          stateCode: overrideState,
          input: {
            rate: getRate()
          }
        }
      });

      setOpenAddOverrideModal(false);
      enqueueSnackbar('Hurray! Region override changed successfully!', {
        variant: 'success'
      });

      await refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const getRate = () => {
    const rateAsNumber = parseFloat(overrideRate);
    if (isNaN(rateAsNumber)) {
      return 0;
    }
    return rateAsNumber / 100;
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,3}$/;
    if (regex.test(value)) {
      setOverrideRate(value);
    }
  };

  return (
    <Dialog open={openAddOverrideModal} onOpenChange={() => setOpenAddOverrideModal(!openAddOverrideModal)}>
      <DialogContent className="!gap-0" size={'sm'} close={true}>
        <div>
          <DialogHeader>
            <DialogTitle className="p-4">
              <p className="line-clamp-1">
                {actionType === 'add' ? 'Add' : 'Edit'} region to override for{' '}
                {regionOverrideData?.overrideTaxDetail?.collection?.title} Collection
              </p>
            </DialogTitle>
            <DialogDescription className="!p-0 !m-0">
              <div className="my-2 px-4 text-[#121b38]">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label size={'paragraph'}>Location</Label>
                    <SelectCN
                      value={overrideState}
                      onValueChange={(value) => {
                        setOverrideState(value);
                      }}
                      defaultValue="IN"
                      disabled={actionType === 'edit'}
                    >
                      <SelectTrigger className="h-10 mt-1 w-full">
                        <div className="w-[225px] overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                          <SelectValue placeholder="'Select State" />
                        </div>
                      </SelectTrigger>
                      <SelectContent side="bottom" longList={true}>
                        {/* eslint-disable-next-line camelcase */}
                        {(actionType === 'add' ? filteredStates : indianStates).map(({ state_code, state }) => (
                          // eslint-disable-next-line camelcase
                          <SelectItem key={state_code} value={state_code}>
                            {state}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectCN>
                  </div>
                  <div>
                    <Label size={'paragraph'}>Tax rate</Label>
                    <Input
                      value={overrideRate}
                      onChange={handleRateChange}
                      type="number"
                      iconPosition="end"
                      icon={<Unicons.UilPercentage size={18} className="mt-1" />}
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4" />
            </DialogDescription>
          </DialogHeader>
        </div>
        <DialogFooter>
          <Button
            variant={'outline'}
            size={'md'}
            onClick={() => {
              setOpenAddOverrideModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={'default'}
            size={'sm'}
            className={actionType === 'add' ? '!w-[174px]' : '!w-[85px]'}
            onClick={async () => {
              if (actionType === 'add') await handleAddProvinceInTaxDetail();
              if (actionType === 'edit') await handleUpdateProvinceInTaxDetail();
            }}
            disabled={
              !overrideState.length ||
              !overrideRate.length ||
              parseFloat(overrideRate.toString()) <= 0 ||
              parseFloat(overrideRate.toString()) > 100
            }
          >
            {addLoading || updateLoading ? (
              <LoadingIcon height={20} className={'animate-spin text-white'} />
            ) : actionType === 'add' ? (
              'Add region to override'
            ) : (
              'Save'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
