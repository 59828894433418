import React, { useEffect } from 'react';
import { CustomizationLeftPanel } from '../../components/customization/customization-left-panel';
import { CustomizationRightPanel } from '../../components/customization/customization-right-panel';
import LayoutWrapper from '../../components/layout/layout-wrapper';
import posthog from 'posthog-js';
import { Title } from '../../components/shared/dashboard-title/title';
import { Route, Routes } from 'react-router-dom';

export const Customization: React.FC = () => {
  useEffect(() => {
    posthog.startSessionRecording();

    return () => {
      posthog.stopSessionRecording();
    };
  }, []);

  return (
    <LayoutWrapper>
      <div className="mx-4">
        <Title title="Customization" />
        <div className="w-full flex relative mt-0 sm:mt-2 gap-0 sm:gap-4">
          <div className="w-full sm:w-[65%] xl:w-[72%]">
            <Routes>
              <Route path="*" element={<CustomizationLeftPanel />} />
            </Routes>
          </div>
          <div className="w-0 sm:w-[35%] xl:w-[28%]">
            <CustomizationRightPanel />
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
