export const paymentMethods = ['upi', 'wallet', 'card', 'emi', 'pay_later', 'net_banking'];

export const InfoCardSetup = [
  {
    heading: 'How does the discount apply?',
    info: ['Applies automatically when user selects payment method']
  },
  {
    heading: 'What is the discount value?',
    info: [
      'Percentage off: Customers get a percentage off on their purchase.',
      ' Fixed amount: Customers get a fixed amount off on their purchase.'
    ]
  },
  {
    heading: 'What to apply discounts to?',
    info: [
      'All online paymment modes: Applicable to all online payment methods.',
      'Set individual payment methods: Applicable to specific payment methods.'
    ]
  }
];

export const InfoCardApplication = [
  {
    heading: 'What does the discount applies to?',
    info: [
      'Total order value: Customers get a discount on the total order value.',
      'Specific products: Customers get a discount on specific products only.'
    ]
  },
  {
    heading: 'Are there any purchase requirements?',
    info: [
      'No requirement: The offer can be applied on any order.',
      'Purchase amount: The offer can be applied only when total cart value meets a certain amount.',
      'Purchase quantity: The offer can be applied only when total cart items meets a certain quantity.'
    ]
  }
];

export const InfoCardUserEligibility = [
  {
    heading: 'User Eligibility',
    info: [
      'All users: This offer is applicable to all users.',
      'First time users: This offer is applicable to first time users only.',
      'Total Order Count: The offer is applicable only when the users have specific number of orders.'
    ]
  }
];

export const InfoCardUsageLimit = [
  {
    heading: 'Maximum discount usage',
    info: []
  },
  {
    heading: 'Discount active period',
    info: ['Set a start and end(optional) date during which this discount will be applicable.']
  },
  {
    heading: 'Combine',
    info: ['Selecting this option allows this coupon to be combined with other discounts']
  }
];
