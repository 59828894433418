/* eslint-disable camelcase */
import debounce from 'debounce';
import { enqueueSnackbar } from 'notistack';
import { ColumnDef } from '@tanstack/react-table';
import * as Unicons from '@iconscout/react-unicons';
import {
  ApplicationType,
  CouponType,
  useDeleteCouponMutation,
  useGetCouponsLazyQuery,
  useUpdateCouponMutation
} from '../../graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { formatIndianRupees } from '../../utils/format-currency';
import { Title } from '../../components/shared/dashboard-title/title';
import { CouponChip } from '../../components/coupons/coupon-chip/coupon-chip';
import { Button, Checkbox, DataTable, Input, Switch } from '../../components/flexyui';
import { DiscountBxgyDialog } from '../../components/discounts/discount-bxgy-dialog';
import { DataTableColumnHeader } from '../../components/flexyui/DataTable/column-header';
import { DiscountAmountDialog } from '../../components/discounts/discount-amount-dialog';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { AddCouponDialog } from '../../components/coupons/add-coupon-dialog/add-coupon-dialog';
import { CreateDiscountPreview } from '../../components/coupons/coupons-preview/coupons-preview';
import { getCollectionSelection, getProductSelection } from '../../utils/discount/handle-selection-data';
import { DiscountPurchaseDrivenDialog } from '../../components/discounts/discount-purchase-driven-dialog';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '../../components/flexyui/Drawer';

export const Coupons = () => {
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'code',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader className="" column={column} title="Code" />,
      cell: ({ row }) => {
        return (
          <div className="py-2 px-3 flex items-center font-semibold" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.code} <CouponChip data={row} />
          </div>
        );
      }
    },
    {
      accessorKey: 'orders',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Orders" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="text-center py-2 px-3" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.orders}
          </div>
        );
      }
    },
    {
      accessorKey: 'gross-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Gross Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex py-2 px-3 justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['gross-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'discount-amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Discount Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-2 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['discount-amount']}
          </div>
        );
      }
    },
    {
      accessorKey: 'net-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Net Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-2 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['net-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Visibility" className="flex justify-center" />
      ),
      id: 'visibility',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center">
            {row.original.row?.application_type !== ApplicationType.Automatic ? (
              <Checkbox
                disabled={row.original.row?.application_type === ApplicationType.Automatic}
                checked={
                  row.original.row?.application_type === ApplicationType.Automatic
                    ? false
                    : row.original.visibilityLoading
                      ? !row.original.row?.discoverable
                      : row.original.row?.discoverable
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                onChange={async () => {
                  await handleActiveStatus(row.original.row, 'visibility', row.original.id);
                }}
              />
            ) : (
              <> - </>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      id: 'Active',
      cell: ({ row }) => {
        return (
          <div className="flex py-2 px-3 justify-center items-center">
            <Switch
              checked={row?.original.activeLoading ? !row?.original.active : row?.original.active}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onCheckedChange={() => handleActiveStatus(row?.original.row, 'active', row.original.id)}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      id: 'Action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1.5 px-3">
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setCouponToEdit(row?.original.row);
                openDiscountDialog(row?.original.row.coupon_type);
              }}
              className="mr-2"
            >
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74] cursor-pointer" />
            </Button>
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setCouponToDelete(row?.original?.id || '');
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const [couponToAdd, setCouponToAdd] = useState<boolean>(false);
  const [couponToEdit, setCouponToEdit] = useState<any>();
  const [couponToDelete, setCouponToDelete] = useState<string>('');

  const [showAmountDiscount, setShowAmountDiscount] = useState<boolean>(false);
  const [showBxgyDiscount, setShowBxgyDiscount] = useState<boolean>(false);
  const [showPurchaseDrivenDiscount, setShowPurchaseDrivenDiscount] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [rows, setRows] = useState<any>(null);

  const [searchedValue, setSearchedValue] = useState({
    input: '',
    skip: 0,
    limit: 10
  });

  const [getCoupons, { data: couponData, loading, refetch }] = useGetCouponsLazyQuery({
    variables: {
      code: '',
      couponTypes: [
        CouponType.Bxgy,
        CouponType.Amount,
        CouponType.CartAmount,
        CouponType.ProductAmount,
        CouponType.PurchaseHistory
      ],
      skip: 0,
      limit: rowPerPage
    }
  });

  useEffect(() => {
    const rowData = couponData?.getCoupons.data.map((row) => {
      return {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: false,
        visibilityLoading: false,
        row
      };
    });
    setRows(rowData);
  }, [couponData]);

  const updateColumnData = (type: 'success' | 'error', rowId: string, handler: 'status' | 'visibility') => {
    const rowData = couponData?.getCoupons.data.map((row) => {
      const loadingState = type === 'success' && row.id === rowId;

      const commonData = {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        row
      };

      return {
        ...commonData,
        activeLoading: handler === 'status' ? loadingState : false,
        visibilityLoading: handler === 'visibility' ? loadingState : false
      };
    });

    setRows(rowData);
  };

  const [updateCoupon] = useUpdateCouponMutation();
  const [deleteCouponMutation, { loading: deleting }] = useDeleteCouponMutation();

  const handleDeleteCouponMethod = async () => {
    try {
      await deleteCouponMutation({
        variables: {
          couponId: couponToDelete
        }
      });
      enqueueSnackbar('Hurray! Coupon deleted successfully!', {
        variant: 'success'
      });
      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setCouponToDelete('');
    }
  };

  const getNetSale = (sales: number) => {
    return formatIndianRupees(Number(sales.toFixed(2) || '0'));
  };
  const getDiscountAmount = (discount: number) => {
    return formatIndianRupees(Number(discount.toFixed(2) || '0'));
  };
  const getGrossSales = (sale: number) => {
    return formatIndianRupees(Number(sale.toFixed(2) || '0'));
  };
  const getOrdersCount = (count: number) => {
    return count || '0';
  };

  const handleActiveStatus = async (updatedData: any, type: any, rowId: string) => {
    const {
      id,
      metrics,
      payment_offers,
      platform,
      tags,
      purchase_requirement,
      application,
      purchase_activity,
      ...rest
    } = updatedData;
    const { collection_selections, product_selections, ...restPurchaseRequirements } = purchase_requirement;
    const { collection_selections: collection, product_selections: product, ...restApplications } = application;

    const constructPurchaseActivityData = () => {
      const couponId = purchase_activity?.coupon_usage?.coupon?.id;
      const couponUsage =
        purchase_activity?.coupon_usage === null ? null : purchase_activity ? { coupon_id: couponId } : null;

      return purchase_activity
        ? {
            ...purchase_activity,
            coupon_usage: couponUsage
          }
        : null;
    };

    const commonCouponData = {
      ...rest,
      application: {
        ...restApplications,
        products: getProductSelection(updatedData?.application?.products),
        collections: getCollectionSelection(updatedData?.application?.collections),
        type: updatedData?.application?.type
      },
      purchase_requirement: {
        ...restPurchaseRequirements,
        products: getProductSelection(updatedData?.purchase_requirement?.products),
        collections: getCollectionSelection(updatedData?.purchase_requirement?.collections)
      },
      purchase_activity: constructPurchaseActivityData()
    };

    try {
      if (type === 'visibility') {
        updateColumnData('success', rowId, 'visibility');
        await updateCoupon({
          variables: {
            couponId: updatedData?.id,
            data: {
              ...commonCouponData,
              discoverable: !updatedData?.discoverable,
              active: updatedData?.active
            }
          }
        });
      } else {
        updateColumnData('success', rowId, 'status');
        await updateCoupon({
          variables: {
            couponId: updatedData?.id,
            data: {
              ...commonCouponData,
              discoverable: updatedData?.discoverable,
              active: !updatedData.active
            }
          }
        });
      }

      const messageType = type === 'visibility' ? 'visibility' : 'activation';
      enqueueSnackbar(`Hurray! Coupon ${messageType} updated successfully!`, {
        variant: 'success'
      });
    } catch (error) {
      updateColumnData('error', rowId, 'status');
      updateColumnData('error', rowId, 'visibility');
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
    await refetch();
  };

  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setShowDetailsModal(true);
      setCouponToEdit(item?.row);
    }
  };

  const handleConfirm = () => {
    handleDeleteCouponMethod();
    setShowConfirmationDialog(false);
  };

  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPageByValue(value: any) {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  }

  const handleUserInput = (e: any, type: string) => {
    setSearchedValue((prevState) => {
      return {
        ...prevState,
        [type]: e.target.value
      };
    });
  };

  const handleSearch = (searchParams: any, skip: number, limit: number) => {
    getCoupons({
      variables: {
        code: searchParams.input || '',
        skip,
        limit,
        couponTypes: [
          CouponType.Bxgy,
          CouponType.Amount,
          CouponType.CartAmount,
          CouponType.ProductAmount,
          CouponType.PurchaseHistory
        ]
      }
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    debouncedSearch(searchedValue, rowPerPage * page, rowPerPage);
  }, [searchedValue, rowPerPage, page]);

  const openDiscountDialog = (couponType: CouponType) => {
    switch (couponType) {
      case CouponType.Bxgy:
        setShowBxgyDiscount(true);
        break;
      case CouponType.CartAmount:
      case CouponType.ProductAmount:
        setShowAmountDiscount(true);
        break;
      case CouponType.PurchaseHistory:
        setShowPurchaseDrivenDiscount(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="mx-4">
        <Title title="Coupons">
          <Button variant="primary" size="sm" onClick={() => setCouponToAdd(true)} disabled={loading}>
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Coupon
          </Button>
        </Title>
        <Input
          placeholder="Search"
          value={searchedValue.input}
          onChange={(e) => handleUserInput(e, 'input')}
          icon={<Unicons.UilSearch size={18} />}
          iconPosition={'start'}
          className="w-full sm:w-60"
        />

        <div className="mt-4">
          <DataTable
            columns={columns}
            data={rows}
            showFilterInput={false}
            showPagination={true}
            onRowClick={onRowClickHandler}
            rowSelection={false}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={couponData?.getCoupons?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
          />
        </div>
      </div>

      <AddCouponDialog open={couponToAdd} setOpen={setCouponToAdd} openDiscountDialog={openDiscountDialog} />

      {showAmountDiscount && (
        <DiscountAmountDialog
          open={showAmountDiscount}
          setOpen={setShowAmountDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}
      {showBxgyDiscount && (
        <DiscountBxgyDialog
          open={showBxgyDiscount}
          setOpen={setShowBxgyDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}
      {showPurchaseDrivenDiscount && (
        <DiscountPurchaseDrivenDialog
          open={showPurchaseDrivenDiscount}
          setOpen={setShowPurchaseDrivenDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleConfirm}
        headerText="Delete Coupon?"
        text="This Action will delete the selected coupons"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleting}
      />

      <Drawer direction="right" open={showDetailsModal} onOpenChange={(value: boolean) => setShowDetailsModal(value)}>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between items-center mb-4">
              <DrawerTitle>Coupon Details</DrawerTitle>
              <Button size="icon" variant="icon" onClick={() => setShowDetailsModal(false)}>
                <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
              </Button>
            </div>
            <DrawerDescription className="text-black text-left">
              <CreateDiscountPreview
                activeStep={4}
                selectedCoupon={couponToEdit}
                isEdit={showAmountDiscount || showBxgyDiscount || showPurchaseDrivenDiscount}
              />
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </>
  );
};
