import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  text: any;
};

export const PaymentStatus: React.FC<Props> = ({ text }) => {
  switch (text) {
    case 'PENDING':
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#736000] bg-[#FFEDBD] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilClock className="h-4" /> Pending
        </div>
      );
    case 'REFUNDED':
      return (
        <div className="flex gap-0.5 items-center text-[#595F74] bg-[#E7E7E7] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilCornerUpLeft className="h-4" /> Refunded
        </div>
      );
    case 'PARTIALLY_PAID':
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#736000] bg-[#FFEDBD] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilAdjustHalf className="h-4" /> Partially paid
        </div>
      );
    case 'COMPLETED':
      return (
        <div className="flex gap-0.5 items-center text-[#007305] bg-[#C7FFE0] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilCheck className="h-4" /> Paid
        </div>
      );

    default:
      return <>-</>;
  }
};
