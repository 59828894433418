import dayjs from 'dayjs';
import { CouponType } from '../../graphql';
import { paymentMethods } from '../../constants/payment-offers';

export const getPaymentOfferInitialValues = (offerDetails: any) => {
  const initializePaymentOffers = () => {
    const paymentOffers: any = {};
    paymentMethods.forEach((method) => {
      const methodKey = method.toUpperCase();
      paymentOffers[method] = {
        active: offerDetails?.payment_offers?.[methodKey]?.active || false,
        amount: offerDetails?.payment_offers?.[methodKey]?.amount || '',
        discount_max_cap: offerDetails?.payment_offers?.[methodKey]?.discount_max_cap || '',
        show_discount_max_cap:
          (offerDetails?.payment_offers?.[methodKey] &&
            offerDetails?.payment_offers?.[methodKey]?.discount_max_cap !== 0) ||
          false
      };
    });
    return paymentOffers;
  };

  return {
    code: offerDetails?.code || '',
    coupon_type: CouponType.Payment,
    discount_type: offerDetails?.discount_type || 'PERCENTAGE',
    discount_amount: offerDetails?.payment_offers?.ALL !== null ? offerDetails?.payment_offers?.ALL?.amount : '',
    discount_max_cap:
      (offerDetails?.payment_offers?.ALL !== null && offerDetails?.payment_offers?.ALL?.discount_max_cap) || '',
    show_discount_max_cap: offerDetails?.payment_offers?.ALL?.discount_max_cap > 0,
    apply_discount_to: offerDetails?.payment_offers?.ALL !== null ? 'ALL_PAYMENT_MODE' : 'SPECIFIC_PAYMENT_MODE',
    payment_offers: initializePaymentOffers(),
    application: {
      type: offerDetails?.application?.type || 'UNIVERSAL'
    },
    purchase_requirement: {
      type: offerDetails?.purchase_requirement?.type || 'NONE',
      show_max_amount: !!offerDetails?.purchase_requirement?.max_amount,
      show_max_quantity: !!offerDetails?.purchase_requirement?.max_quantity,
      min_amount: offerDetails?.purchase_requirement?.min_amount || null,
      max_amount: offerDetails?.purchase_requirement?.max_amount || null,
      min_quantity: offerDetails?.purchase_requirement?.min_quantity || null,
      max_quantity: offerDetails?.purchase_requirement?.max_quantity || null,
      productType: offerDetails?.purchase_requirement?.products?.length > 0 ? 'PURCHASE_PRODUCTS' : 'PURCHASE_CART',
      products: offerDetails?.purchase_requirement?.products || []
    },
    user_order_requirement: {
      max_order_count: offerDetails?.user_order_requirement?.max_order_count || null,
      max_order_count_needed: !!offerDetails?.user_order_requirement?.max_order_count,
      min_order_count: offerDetails?.user_order_requirement?.min_order_count || null,
      type:
        offerDetails?.user_order_requirement?.max_order_count === 0 &&
        offerDetails?.user_order_requirement?.min_order_count === null
          ? 'first'
          : offerDetails?.user_order_requirement?.min_order_count >= 0 &&
              offerDetails?.user_order_requirement?.min_order_count !== null
            ? 'order'
            : 'all'
    },
    show_per_customer_usage_limit: !!offerDetails?.per_customer_usage_limit,
    show_total_usage_limit: !!offerDetails?.total_usage_limit,
    discoverable: offerDetails?.discoverable || false,
    combine: false,
    activation_period: {
      start: offerDetails?.activation_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
      end: offerDetails?.activation_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
      has_endTime: !!offerDetails?.activation_period?.end
    },
    per_customer_usage_limit: offerDetails?.per_customer_usage_limit || null,
    total_usage_limit: offerDetails?.total_usage_limit || null
  };
};
