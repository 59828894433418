import React from 'react';
import { Skeleton } from '../../flexyui';

export const LineItemSkeleton = () => {
  return (
    <div className="p-4 border-b-[1px] last:border-b-0">
      <div className="flex items-center justify-between ">
        <div className="flex items-center justify-between w-[70%]">
          <div className="flex items-center gap-2">
            <div className="w-[50px] flex justify-center">
              <Skeleton size={'xl'} className="h-11" />
            </div>
            <div>
              <Skeleton size={'label'} className="w-40" />
              <Skeleton size={'md'} className="mt-1.5" />
            </div>
          </div>
          <Skeleton size={'label'} />
        </div>
        <div className="flex flex-row-reverse items-center gap-4 w-[30%]">
          <Skeleton className="h-10 w-10" />
          <Skeleton className="h-10 w-24" />
        </div>
      </div>
      <Skeleton size={'md'} className="mt-2 w-32" />
    </div>
  );
};
