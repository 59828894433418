import * as Yup from 'yup';
import { DiscountType } from '../../../graphql';
import { DiscountSchema, PurchaseRequirementSchema } from './index';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';
import { paymentMethods } from '../../../constants/payment-offers';

const paymentMethodSchema = () => {
  return Yup.object().shape({
    active: Yup.boolean().default(false),
    amount: Yup.number()
      .test('required', requiredError('Amount'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' && parent.active) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active
        )
          return val > 0;
        else return true;
      })
      .test('percentage', ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          from?.[2]?.value['discount_type'] === DiscountType.Percentage
        )
          return val < 100;
        else return true;
      }),
    discount_max_cap: Yup.number()
      .test('required', requiredError('Maximum cap'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          parent.show_discount_max_cap
        ) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          parent.show_discount_max_cap
        )
          return val > 0;
        else return true;
      }),
    show_discount_max_cap: Yup.boolean().default(false)
  });
};

export const DiscountPaymentSchema = DiscountSchema.omit(['title', 'has_title', 'application_type']).shape({
  discount_type: Yup.string().default(DiscountType.Percentage).required(),
  discount_amount: Yup.number()
    .when('apply_discount_to', {
      is: (val: string) => val === 'ALL_PAYMENT_MODE',
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Discount value'))
    })
    .when(['apply_discount_to', 'discount_type'], {
      is: (applyDiscountTo: string, discountType: DiscountType) =>
        applyDiscountTo === 'ALL_PAYMENT_MODE' && discountType === DiscountType.Percentage,
      then: (schema) => schema.lessThan(100, ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR)
    }),
  discount_max_cap: Yup.number().when(['apply_discount_to', 'discount_type', 'show_discount_max_cap'], {
    is: (applyDiscountTo: string, discountType: DiscountType, showDiscountMaxCap: boolean) =>
      applyDiscountTo === 'ALL_PAYMENT_MODE' && discountType === DiscountType.Percentage && showDiscountMaxCap,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Maximum cap')),
    otherwise: (schema) => schema.nullable()
  }),
  show_discount_max_cap: Yup.boolean().default(false),
  apply_discount_to: Yup.string()
    .default('ALL_PAYMENT_MODE')
    .test('required', 'This field is required', function () {
      // eslint-disable-next-line no-invalid-this
      const { parent }: any = this;
      return (
        parent.apply_discount_to !== 'SPECIFIC_PAYMENT_MODE' ||
        paymentMethods.some((method) => parent?.payment_offers[method]?.active)
      );
    }),
  payment_offers: Yup.object().shape({
    upi: paymentMethodSchema(),
    emi: paymentMethodSchema(),
    wallet: paymentMethodSchema(),
    pay_later: paymentMethodSchema(),
    net_banking: paymentMethodSchema(),
    card: paymentMethodSchema()
  }),
  purchase_requirement: PurchaseRequirementSchema.shape({
    products: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_PRODUCTS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR),
        otherwise: (schema) => schema
      })
  })
});
