import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Input, RadioGroup } from '../../../flexyui';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { InfoCardSetup } from '../../../../constants/coupons';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';
import { ApplicationTarget, ApplicationType, CouponType } from '../../../../graphql';

export const DiscountCode = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  useEffect(() => {
    if (values?.coupon_type === CouponType.ProductAmount) setFieldValue('application.type', ApplicationTarget.Products);
    else setFieldValue('application.type', ApplicationTarget.Universal);
  }, [values?.coupon_type]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
      <DiscountInfoCard data={InfoCardSetup[0]} />
      <div>
        <div className="font-semibold text-base mb-4 block sm:hidden">How does the discount apply?</div>
        <RadioGroup {...getFieldProps('application_type')} defaultValue={ApplicationType.Code}>
          <RadioWithLabel
            label="Coupon Code"
            value={ApplicationType.Code}
            onClick={() => setFieldValue('application_type', ApplicationType.Code)}
          />
          <RadioWithLabel
            label="Automatic"
            value={ApplicationType.Automatic}
            onClick={() => setFieldValue('application_type', ApplicationType.Automatic)}
          />
        </RadioGroup>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="font-medium mt-4 mb-1">Discount Code</div>
            <Input
              {...getFieldProps('code')}
              type="text"
              error={touched.code && !!errors.code}
              errorMessage={errors.code}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let fieldValue = e.target.value;
                if (values.application_type === ApplicationType.Code) {
                  fieldValue = e.target.value.toUpperCase();
                }
                setFieldValue('code', fieldValue);
              }}
            />
          </div>
          {values?.has_title && (
            <div>
              <div className="font-medium mt-4 mb-1">Discount Title</div>
              <Input
                {...getFieldProps('title')}
                type="text"
                error={touched.title && !!errors.title}
                errorMessage={errors.title}
              />
            </div>
          )}
        </div>

        <CheckboxWithLabel
          label="Custom discount title"
          value="has_title"
          checked={values.has_title}
          onClick={() => setFieldValue('has_title', !values.has_title)}
        />

        {values.application_type !== ApplicationType.Automatic && (
          <CheckboxWithLabel
            label="Show discount coupon to customer"
            value="discoverable"
            checked={values.discoverable}
            onClick={() => setFieldValue('discoverable', !values.discoverable)}
          />
        )}
      </div>
    </div>
  );
};
