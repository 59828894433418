import React from 'react';
import { Summary } from '../../../graphql';

type Props = {
  summary: Summary;
};

export const OrderSummary: React.FC<Props> = ({ summary }) => {
  return (
    <>
      <div className="flex items-center justify-between pb-2">
        <div>Updated total</div>
        <div>₹{summary?.subtotal_amount?.toFixed(2)}</div>
      </div>
      {summary?.order_level_discount ? (
        <div className="flex items-center justify-between pb-2">
          <div>Order level discount</div>
          <div>-₹{summary?.order_level_discount?.toFixed(2)}</div>
        </div>
      ) : null}
      <div className="flex items-center justify-between pb-2">
        <div>Shipping charges</div>
        <div>₹{summary?.shipping_amount?.toFixed(2)}</div>
      </div>
      <div className="flex items-center justify-between pb-2">
        <div>Taxes</div>
        <div>₹{summary?.tax_amount?.toFixed(2)}</div>
      </div>
      <div className="flex items-center justify-between pb-3">
        <div>Paid by customer</div>
        <div>₹{summary?.paid_amount?.toFixed(2)}</div>
      </div>
      <hr />
      <div className="flex items-center justify-between font-medium pt-3">
        <div>Amount to {(summary?.outstanding_amount || 0) >= 0 ? 'Collect' : 'Refund'}</div>
        <div>₹{Math.abs(summary?.outstanding_amount || 0)?.toFixed(2)}</div>
      </div>
    </>
  );
};
