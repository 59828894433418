import dayjs from 'dayjs';
import { paymentMethods } from '../../constants/payment-offers';
import { getProductSelection } from '../discount/handle-selection-data';

export const paymentOfferDataHandler = (values: any) => {
  const body = { ...values };
  const paymentOffers: any = {};

  if (body.apply_discount_to === 'ALL_PAYMENT_MODE') {
    paymentOffers.ALL = {
      active: true,
      amount: body.discount_amount,
      discount_max_cap: body.show_discount_max_cap !== false ? body?.discount_max_cap : 0
    };
    paymentMethods.forEach((method) => (paymentOffers[method.toUpperCase()] = null));
  } else if (body.apply_discount_to === 'SPECIFIC_PAYMENT_MODE') {
    paymentMethods.forEach((method) => (paymentOffers[method.toUpperCase()] = null));

    paymentMethods.forEach((method) => {
      if (body.payment_offers[method].active) {
        paymentOffers[method.toUpperCase()] = {
          active: body.payment_offers[method].active,
          amount: body.payment_offers[method].amount,
          discount_max_cap:
            body.payment_offers[method].show_discount_max_cap !== false
              ? body.payment_offers[method].discount_max_cap
              : 0
        };
      }
    });
  }
  body.payment_offers = paymentOffers;

  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }
  if (body?.purchase_requirement?.productType === 'PURCHASE_CART') {
    delete body.purchase_requirement.products;
  }
  if (body.purchase_requirement.products && body.purchase_requirement.products.length > 0) {
    body.purchase_requirement.products = getProductSelection(body.purchase_requirement.products);
  }
  if (body?.purchase_requirement?.type === 'NONE') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
    delete body.purchase_requirement.products;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_AMOUNT') {
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_QUANTITY') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
  }

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
  } else if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
  } else if (body?.user_order_requirement?.type === 'order') {
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  } else body.total_usage_limit = null;
  if (body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  } else body.per_customer_usage_limit = null;

  if (body?.activation_period?.start) {
    body.activation_period.start = dayjs(body.activation_period.start).toISOString();
  } else body.activation_period.start = dayjs(new Date()).toISOString();
  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end) body.activation_period.end = dayjs(body.activation_period.end).toISOString();
    else body.activation_period.end = dayjs(new Date()).toISOString();
  } else body.activation_period.end = null;

  delete body.coupon_type;
  delete body.discount_amount;
  delete body.discount_max_cap;
  delete body.show_discount_max_cap;
  delete body.apply_discount_to;
  delete body.purchase_requirement.show_max_amount;
  delete body.purchase_requirement.show_max_quantity;
  delete body.purchase_requirement.productType;
  delete body.user_order_requirement?.type;
  delete body.user_order_requirement?.max_order_count_needed;
  delete body.show_total_usage_limit;
  delete body.show_per_customer_usage_limit;
  delete body.combine;
  delete body.activation_period.has_endTime;

  return body;
};
