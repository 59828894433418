import React, { useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Input } from '../../flexyui/Input';

interface CredentialsInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | undefined;
}

export const CredentialsInput: React.FC<CredentialsInputProps> = ({ value = '', ...props }) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const handleHide = () => setIsHidden(!isHidden);

  return (
    <Input
      type={isHidden ? 'password' : 'test'}
      value={value}
      icon={
        isHidden ? (
          <Unicons.UilEye size={18} onClick={handleHide} className="cursor-pointer mt-[5px]" />
        ) : (
          <Unicons.UilEyeSlash size={18} onClick={handleHide} className="cursor-pointer mt-[5px]" />
        )
      }
      iconPosition="end"
      className="w-full focus-visible:ring-offset-0 focus-visible:ring-0 mt-1"
      {...props}
    />
  );
};
