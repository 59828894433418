import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  usePaymentMethodsQuery,
  useUpdatePaymentMethodMutation,
  PaymentMethod,
  PaymentProvider
} from '../../../graphql';
import {
  Card,
  Select as SelectCN,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton
} from '../../flexyui';
import { paymentMethods } from '../../../constants/settings';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';

export const SettingsPayment: React.FC = () => {
  const { data, loading, refetch } = usePaymentMethodsQuery();
  const [showModal, setShowModal] = useState(false);
  const [methodsToShow, setMethodsToShow] = useState<string[]>([]);
  const [paymentMethodToChange, setPaymentMethodToChange] = useState<any>();
  const [updatePaymentMutation, { loading: loadingMutation }] = useUpdatePaymentMethodMutation();

  // TODO: Don't Remove
  // const methodsSequence = ['UPI', 'WALLET', 'CARD', 'NET_BANKING', 'EMI', 'PAY_LATER', 'ONLINE'];
  const handleProviderChange = async (item: PaymentMethod, value: PaymentProvider) => {
    await updatePaymentMutation({
      variables: {
        method: item,
        provider: value
      }
    });

    await refetch();
  };

  const handleConfirm = () => {
    handleProviderChange(paymentMethodToChange.method, paymentMethodToChange.provider);
    setShowModal(false);
  };

  function paymentsMethodsFilter(obj: any): string[] {
    const result: string[] = [];

    for (const key in obj) {
      if (obj[key] !== null) {
        result.push(key);
      }
    }

    return result;
  }

  useEffect(() => {
    const methods = paymentsMethodsFilter(data?.paymentMethods);
    setMethodsToShow(methods);
  }, [data?.paymentMethods]);

  return (
    <>
      <Card className="h-fit px-4 pt-4 mb-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]">
        <div className="font-medium mb-4">Payment Methods</div>
        {loading &&
          [0, 1, 2, 3, 4].map((item, index) => (
            <div key={item} className={`flex items-center justify-between py-4 ${index !== 4 && 'border-b'}`}>
              <div className="flex items-center gap-4">
                <Skeleton className="!m-0 h-8 w-8 " />
                <Skeleton className="w-[130px] !m-0" />
              </div>
              <Skeleton className="w-[110px] h-[40px] !m-0" />
            </div>
          ))}
        {methodsToShow?.map((payment: any, index) => {
          const method = { ...data?.paymentMethods };
          const payments = paymentMethods?.[payment];

          if (!data) return <></>;
          return (
            <>
              <div className="flex justify-between items-center py-4">
                <div className="flex items-center gap-2">
                  <Card className="p-[6px] mr-3">
                    {typeof payments.Icon === 'string' ? (
                      <img src={payments.Icon} alt="payment methods" className="w-6 h-6" />
                    ) : (
                      payments.Icon && <payments.Icon />
                    )}
                  </Card>
                  <div className="flex-row">
                    <div className="text-[#595F74]">{payments.title}</div>
                  </div>
                </div>
                {payments.title !== 'Cash on Delivery' && (
                  <SelectCN
                    value={method?.[payment as PaymentMethod]?.provider}
                    onValueChange={(value) => {
                      setShowModal(true);
                      setPaymentMethodToChange({
                        method: payment,
                        provider: value as PaymentProvider
                      });
                    }}
                    defaultValue={PaymentProvider.None}
                  >
                    <SelectTrigger className="h-10 w-[110px] sm:w-[250px]">
                      <SelectValue placeholder="Provider" />
                    </SelectTrigger>
                    <SelectContent side="bottom">
                      {payments.paymentGateways.map((provider) => {
                        return (
                          <SelectItem key={provider} value={provider}>
                            {_.capitalize(provider)}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </SelectCN>
                )}
              </div>
              {methodsToShow.length > 1 && index !== methodsToShow.length - 1 && <hr />}
            </>
          );
        })}
        {!data?.paymentMethods && !loading && (
          <div className="text-center my-40">
            <div className="text-gray-300 text-base">Not Available</div>
          </div>
        )}
      </Card>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={handleConfirm}
        text="Confirm payment method change"
        loading={loadingMutation}
      />
    </>
  );
};
