import React from 'react';
import { useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { Input, RadioGroup } from '../../../flexyui';
import { CouponType, DiscountType } from '../../../../graphql';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { OfferFields } from '../../../../types/payment-offers-data-types';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';
import { InfoCardSetup, paymentMethods } from '../../../../constants/payment-offers';

export const DiscountPaymentSetup = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<OfferFields>();

  const getPaymentMethodsLabel = (method: string) => {
    if (method === 'upi' || method === 'emi') return method.toUpperCase();
    else if (method === 'card') return 'Debit/Credit Cards';
    else
      return method
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardSetup[0]} />
        <div>
          <div className="font-semibold text-base block sm:hidden">How does the discount apply?</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <div className="font-medium mt-4 sm:mt-0 mb-2">Discount Title</div>
              <Input
                {...getFieldProps('code')}
                type="text"
                error={touched.code && !!errors.code}
                errorMessage={errors.code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let fieldValue = e.target.value;
                  if (values.coupon_type === CouponType.Payment) {
                    fieldValue = e.target.value.toUpperCase();
                  }
                  setFieldValue('code', fieldValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardSetup[1]} />

        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">What is the discount value?</div>
          <RadioGroup {...getFieldProps('discount_type')} defaultValue={DiscountType.Percentage}>
            <RadioWithLabel
              label="Percentage off"
              value={DiscountType.Percentage}
              onClick={() => setFieldValue('discount_type', DiscountType.Percentage)}
            />
            <RadioWithLabel
              label="Fixed amount"
              value={DiscountType.Fixed}
              onClick={() => setFieldValue('discount_type', DiscountType.Fixed)}
            />
          </RadioGroup>
        </div>
      </div>
      <hr />

      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardSetup[2]} />

        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">What to apply discounts to?</div>
          <RadioGroup {...getFieldProps('apply_discount_to')} defaultValue="ALL_PAYMENT_MODE">
            <RadioWithLabel
              label="All online payment modes"
              value="ALL_PAYMENT_MODE"
              onClick={() => setFieldValue('apply_discount_to', 'ALL_PAYMENT_MODE')}
            />
            <RadioWithLabel
              label="Set for individual payment modes"
              value="SPECIFIC_PAYMENT_MODE"
              onClick={() => setFieldValue('apply_discount_to', 'SPECIFIC_PAYMENT_MODE')}
            />
          </RadioGroup>
          {values?.apply_discount_to && values?.apply_discount_to === 'ALL_PAYMENT_MODE' ? (
            <>
              {values.discount_type && values.discount_type === DiscountType.Percentage ? (
                <>
                  <div className="flex gap-4 mt-4">
                    <div className={'w-[150px]'}>
                      <div className="font-medium mb-1">Percentage off</div>
                      <Input
                        {...getFieldProps('discount_amount')}
                        type="number"
                        iconPosition="end"
                        icon={<Unicons.UilPercentage size={18} />}
                        error={touched.discount_amount && !!errors.discount_amount}
                        errorMessage={errors.discount_amount}
                      />
                    </div>
                    {values.show_discount_max_cap && (
                      <div className={'w-[150px]'}>
                        <div className="font-medium mb-1">Percentage off upto</div>
                        <Input
                          {...getFieldProps('discount_max_cap')}
                          type="number"
                          iconPosition="start"
                          icon={<Unicons.UilRupeeSign size={15} />}
                          error={touched.discount_max_cap && !!errors.discount_max_cap}
                          errorMessage={errors.discount_max_cap}
                        />
                      </div>
                    )}
                  </div>
                  <CheckboxWithLabel
                    label="Maximum purchase amount cap"
                    value="show_discount_max_cap"
                    checked={values.show_discount_max_cap}
                    onClick={() => setFieldValue('show_discount_max_cap', !values.show_discount_max_cap)}
                  />
                </>
              ) : (
                <div className="mt-4 w-[150px]">
                  <Input
                    {...getFieldProps('discount_amount')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.discount_amount && !!errors.discount_amount}
                    errorMessage={errors.discount_amount}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {paymentMethods.map((each) => (
                <div className="flex flex-col">
                  <CheckboxWithLabel
                    label={getPaymentMethodsLabel(each)}
                    value={`payment_offers.${each}.active`}
                    checked={values.payment_offers[each as keyof typeof values.payment_offers].active}
                    onClick={() =>
                      setFieldValue(
                        `payment_offers.${each}.active`,
                        !values.payment_offers[each as keyof typeof values.payment_offers].active
                      )
                    }
                  />
                  <div
                    className={`ml-5 ${
                      values.payment_offers[each as keyof typeof values.payment_offers]?.active && 'mb-2'
                    }`}
                  >
                    {values.payment_offers[each as keyof typeof values.payment_offers]?.active &&
                      (values.discount_type && values.discount_type === DiscountType.Percentage ? (
                        <>
                          <div className="flex gap-4 mt-2">
                            <div className={'w-[150px]'}>
                              <div className="font-medium mb-1">Percentage off</div>
                              <Input
                                {...getFieldProps(`payment_offers.${each}.amount`)}
                                type="number"
                                iconPosition="end"
                                icon={<Unicons.UilPercentage size={18} />}
                                error={
                                  (touched.payment_offers as any)?.[each]?.amount &&
                                  !!(errors.payment_offers as any)?.[each]?.amount
                                }
                                errorMessage={(errors.payment_offers as any)?.[each]?.amount}
                              />
                            </div>
                            {values.payment_offers[each as keyof typeof values.payment_offers]
                              ?.show_discount_max_cap && (
                              <div className={'w-[150px]'}>
                                <div className="font-medium mb-1">Percentage off upto</div>
                                <Input
                                  {...getFieldProps(`payment_offers.${each}.discount_max_cap`)}
                                  type="number"
                                  iconPosition="start"
                                  icon={<Unicons.UilRupeeSign size={15} />}
                                  error={
                                    (touched.payment_offers as any)?.[each]?.discount_max_cap &&
                                    !!(errors.payment_offers as any)?.[each]?.discount_max_cap
                                  }
                                  errorMessage={(errors.payment_offers as any)?.[each]?.discount_max_cap}
                                />
                              </div>
                            )}
                          </div>
                          <CheckboxWithLabel
                            label="Maximum purchase amount cap"
                            value={`payment_offers.${each}.show_discount_max_cap`}
                            checked={
                              values.payment_offers[each as keyof typeof values.payment_offers].show_discount_max_cap
                            }
                            onClick={() =>
                              setFieldValue(
                                `payment_offers.${each}.show_discount_max_cap`,
                                !values.payment_offers[each as keyof typeof values.payment_offers].show_discount_max_cap
                              )
                            }
                          />
                        </>
                      ) : (
                        <div className="mt-2 w-[150px]">
                          <Input
                            {...getFieldProps(`payment_offers.${each}.amount`)}
                            type="number"
                            iconPosition="start"
                            icon={<Unicons.UilRupeeSign size={15} />}
                            error={
                              (touched.payment_offers as any)?.[each]?.amount &&
                              !!(errors.payment_offers as any)?.[each]?.amount
                            }
                            errorMessage={(errors.payment_offers as any)?.[each]?.amount}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
