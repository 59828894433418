import { RtoSuitQuery, useRtoSuitLazyQuery, useUpdateRtoSuiteMutation } from '../../graphql';
import { Button, Card, Label } from '../../components/flexyui';
import LayoutWrapper from '../../components/layout/layout-wrapper';
import FilterPinCodes from '../../components/rto-suite/cod-availability/filter-pin-codes';
import { Formik } from 'formik';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import FilterUsers from '../../components/rto-suite/cod-availability/filter-users';
import { Accordion } from '../../components/flexyui/Accordion';
import FilterCollections from '../../components/rto-suite/cod-availability/filter-collections';
import { codAvailabilityConfigDataHandler, modifyCollectionData, partialCodDataHandler } from '../../utils/rto-suite';
import PartialCod from '../../components/rto-suite/partial-cod/partial-cod';
import { Title } from '../../components/shared/dashboard-title/title';

const RTOSuite = () => {
  const [getRtoSuite, { data: rtoSuite, loading: rtoSuiteLoading, refetch: refetchRtoSuite, fetchMore }] =
    useRtoSuitLazyQuery({
      variables: {
        userSkip: 0,
        userLimit: 10,
        userPhoneNumber: null,
        collectionsSkip: 0,
        collectionsLimit: 100
      }
    });
  const [updateRtoSuite] = useUpdateRtoSuiteMutation();

  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();

  const [loading, setLoading] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState(
    modifyCollectionData(rtoSuite?.codAvailabilityConfig?.collection_filter?.collections)
  );
  const [users, setUsers] = useState(rtoSuite?.codAvailabilityConfig?.user_filter?.users || []);
  const [addedUsers, setAddedUsers] = useState<any[]>([]);
  const [removedUsers, setRemovedUsers] = useState<any[]>([]);

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const partialCodBody = partialCodDataHandler(newValues);
    const codAvailabilityConfigBody = codAvailabilityConfigDataHandler(newValues, addedUsers, removedUsers);

    setLoadingActions(true);
    try {
      await updateRtoSuite({
        variables: {
          codAvailabilityConfig: codAvailabilityConfigBody,
          partialCodConfigs: partialCodBody
        }
      });
      setLoadingActions(false);
      setShowActions(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }

    const userRefetchLength = users.length + addedUsers.length - removedUsers.length;

    await refetchRtoSuite({
      userSkip: 0,
      userLimit: userRefetchLength || rtoSuite?.codAvailabilityConfig?.user_filter?.users?.length || 10,
      userPhoneNumber: null,
      collectionsSkip: 0,
      collectionsLimit:
        selectedCollections.length || rtoSuite?.codAvailabilityConfig?.collection_filter?.collections?.length || 100
    });

    setAddedUsers([]);
    setRemovedUsers([]);
  };

  const loadMoreCollections = () => {
    fetchMore({
      variables: {
        collectionsSkip: rtoSuite?.codAvailabilityConfig?.collection_filter?.collections?.length,
        collectionsLimit: 100
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prevResult;

        return {
          ...prevResult,
          codAvailabilityConfig: {
            ...prevResult.codAvailabilityConfig,
            collection_filter: {
              ...prevResult.codAvailabilityConfig.collection_filter,
              collections: [
                ...prevResult.codAvailabilityConfig.collection_filter.collections,
                ...fetchMoreResult.codAvailabilityConfig.collection_filter.collections
              ]
            }
          }
        };
      }
    });
  };

  const loadMoreUsers = (limit = 10) => {
    setLoading(true);
    fetchMore({
      variables: {
        userSkip: rtoSuite?.codAvailabilityConfig?.user_filter?.users?.length,
        userLimit: limit
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        setLoading(false);
        if (!fetchMoreResult) return prevResult;

        return {
          ...prevResult,
          codAvailabilityConfig: {
            ...prevResult.codAvailabilityConfig,
            user_filter: {
              ...prevResult.codAvailabilityConfig.user_filter,
              users: [
                ...prevResult.codAvailabilityConfig.user_filter.users,
                ...fetchMoreResult.codAvailabilityConfig.user_filter.users
              ]
            }
          }
        };
      }
    });
  };

  const handleUserSearch = (phoneNumber: any) => {
    setLoading(true);
    fetchMore({
      variables: {
        userSkip: 0,
        userLimit: 10,
        userPhoneNumber: phoneNumber ? phoneNumber : null
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          codAvailabilityConfig: {
            ...prev.codAvailabilityConfig,
            user_filter: {
              ...prev.codAvailabilityConfig.user_filter,
              users: fetchMoreResult.codAvailabilityConfig.user_filter.users,
              user_count: fetchMoreResult.codAvailabilityConfig.user_filter.user_count
            }
          }
        };
      }
    });
  };

  useEffect(() => {
    if (rtoSuite?.codAvailabilityConfig) {
      setSelectedCollections(modifyCollectionData(rtoSuite?.codAvailabilityConfig?.collection_filter?.collections));
      setUsers(rtoSuite?.codAvailabilityConfig?.user_filter?.users);
    }
  }, [rtoSuite]);

  useEffect(() => {
    getRtoSuite();
  }, []);

  return (
    <LayoutWrapper>
      <div className="px-4 pb-4">
        <Title title="RTO Suite" />
        <Formik<RtoSuitQuery>
          initialValues={rtoSuite as RtoSuitQuery}
          enableReinitialize
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({ values, submitForm, resetForm }) => {
            useEffect(() => {
              if (rtoSuite && values) {
                const hasChanges = !isEqual(rtoSuite, values);
                if (hasChanges || addedUsers.length > 0 || removedUsers.length > 0) {
                  if (!rtoSuiteLoading) setShowActions(true);
                  if (saveButtonClicked) {
                    submitForm();
                    setShowActions(false);
                    setLoadingActions(false);
                  }
                  if (cancelButtonClicked) {
                    resetForm();
                    setAddedUsers([]);
                    setRemovedUsers([]);
                    setSelectedCollections(
                      modifyCollectionData(rtoSuite?.codAvailabilityConfig?.collection_filter?.collections)
                    );
                    setUsers(rtoSuite?.codAvailabilityConfig?.user_filter?.users);
                    setShowActions(false);
                    setLoadingActions(false);
                  }
                } else {
                  setShowActions(false);
                }
              }
            }, [rtoSuite, values, addedUsers, removedUsers, saveButtonClicked, cancelButtonClicked]);

            return (
              <>
                <Card className="mt-0 sm:mt-2 flex flex-col gap-5  pb-4 border-b border-[#D7D7D7] px-4 pt-4">
                  <div className="flex flex-col space-y-1">
                    <Label size="md" className="text-base">
                      COD Availability
                    </Label>
                    <Label className="text-[#888D9B]" size={'sm'}>
                      Set up COD availability based on multiple rules. All the rules are applied together.
                    </Label>
                  </div>

                  <Accordion
                    type="single"
                    collapsible
                    className="!mt-0 bg-[#FAFAFA] rounded-md border border-[#E3E3E3]"
                    disabled={rtoSuiteLoading}
                  >
                    <FilterCollections
                      selectedCollections={selectedCollections}
                      setSelectedCollections={setSelectedCollections}
                      loadMoreCollections={loadMoreCollections}
                    />
                    <FilterPinCodes />
                    <FilterUsers
                      users={users}
                      loading={loading}
                      addedUsers={addedUsers}
                      setAddedUsers={setAddedUsers}
                      removedUsers={removedUsers}
                      setRemovedUsers={setRemovedUsers}
                      loadMoreUsers={loadMoreUsers}
                      handleUserSearch={handleUserSearch}
                    />
                  </Accordion>
                </Card>
                <PartialCod rtoSuiteLoading={rtoSuiteLoading} />
              </>
            );
          }}
        </Formik>
      </div>
    </LayoutWrapper>
  );
};

export default RTOSuite;
