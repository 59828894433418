import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

export const discountHeader = [
  {
    step: 'Setup',
    logo: <Unicons.UilCheckCircle size={18} />
  },
  {
    step: 'User Behaviour',
    logo: <Unicons.UilUserArrows size={18} />
  },
  {
    step: 'Condition',
    logo: <Unicons.UilShoppingCartAlt size={18} />
  },
  {
    step: 'User Eligibility',
    logo: <Unicons.UilUser size={18} />
  },
  {
    step: 'Usage Limits',
    logo: <Unicons.UilClock size={18} />
  }
];
