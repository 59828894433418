import dayjs from 'dayjs';
import { ItemsFrom } from '../../constants/coupons';
import { getCollectionSelection, getProductSelection } from '../discount/handle-selection-data';
import { ApplicationTarget, ApplicationType, DiscountType, PurchaseActivityType } from '../../graphql';

export const discountAmountDataHandler = (values: any) => {
  const body = { ...values };

  if (body?.has_title === false) {
    body.title = null;
  }

  if (body?.show_discount_max_cap === false) {
    body.discount_max_cap = null;
  }

  if (body?.application_type === ApplicationType.Automatic) {
    body.discoverable = false;
  }
  if (body?.application?.type === ApplicationTarget.Universal) {
    delete body.application.products;
    delete body.application.collections;
  }
  if (body.application.products && body.application.products.length > 0) {
    body.application.products = getProductSelection(body.application.products);
  }
  if (body.application.collections && body.application.collections.length > 0) {
    body.application.collections = getCollectionSelection(body.application.collections);
  }
  if (body?.application?.type === ApplicationTarget.Products) {
    body.application.collections = [];
  } else if (body?.application?.type === ApplicationTarget.Collections) {
    body.application.products = [];
  }

  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }
  if (body?.purchase_requirement?.productType === 'PURCHASE_CART') {
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  }
  if (body.purchase_requirement.products && body.purchase_requirement.products.length > 0) {
    body.purchase_requirement.products = getProductSelection(body.purchase_requirement.products);
  }
  if (body.purchase_requirement.collections && body.purchase_requirement.collections.length > 0) {
    body.purchase_requirement.collections = getCollectionSelection(body.purchase_requirement.collections);
  }
  if (body?.purchase_requirement.productType === 'PURCHASE_PRODUCTS') {
    body.purchase_requirement.collections = [];
  } else if (body?.purchase_requirement.productType === 'PURCHASE_COLLECTIONS') {
    body.purchase_requirement.products = [];
  }
  if (body?.purchase_requirement?.type === 'NONE') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_AMOUNT') {
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_QUANTITY') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
  }

  body.purchase_activity = null;
  body.purchase_activity_period = null;

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
  } else if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
  } else if (body?.user_order_requirement?.type === 'order') {
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  } else body.total_usage_limit = null;
  if (body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  } else body.per_customer_usage_limit = null;

  if (body?.activation_period?.start) {
    body.activation_period.start = dayjs(body.activation_period.start).toISOString();
  } else body.activation_period.start = dayjs(new Date()).toISOString();

  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end) body.activation_period.end = dayjs(body.activation_period.end).toISOString();
    else body.activation_period.end = dayjs(new Date()).toISOString();
  } else body.activation_period.end = null;

  delete body?.has_title;
  delete body.show_discount_max_cap;
  delete body.application.max_per_order;
  delete body.application.min_quantity;
  delete body?.application?.discount_type;
  delete body?.application?.discount_amount;
  delete body?.application?.items_from;
  delete body?.application?.show_max_per_order;
  delete body?.purchase_requirement.show_max_amount;
  delete body?.purchase_requirement.show_max_quantity;
  delete body?.purchase_requirement.productType;
  delete body?.purchase_requirement.items_from;
  delete body?.user_order_requirement?.type;
  delete body?.user_order_requirement?.max_order_count_needed;
  delete body?.show_total_usage_limit;
  delete body?.show_per_customer_usage_limit;
  delete body?.activation_period.has_endTime;

  return body;
};

export const discountBxgyDataHandler = (values: any) => {
  const body = { ...values };

  if (body?.has_title === false) {
    body.title = null;
  }
  if (body?.application_type === ApplicationType.Automatic) {
    body.discoverable = false;
  }

  if (body.application.products && body.application.products.length > 0) {
    body.application.products = getProductSelection(body.application.products);
    body.application.type = ApplicationTarget.Products;
  }
  if (body.application.collections && body.application.collections.length > 0) {
    body.application.collections = getCollectionSelection(body.application.collections);
    body.application.type = ApplicationTarget.Collections;
  }
  if (body?.application?.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
    body.application.collections = [];
  } else if (body?.application?.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
    body.application.products = [];
  }
  if (body?.application?.show_max_per_order === false) {
    body.application.max_per_order = null;
  }
  body.discount_type = body.application.discount_type;
  body.discount_amount = body.application.discount_amount;
  if (body.discount_type === 'FREE') {
    body.discount_type = DiscountType.Percentage;
    body.discount_amount = 100;
  }

  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }
  if (body.purchase_requirement.products && body.purchase_requirement.products.length > 0) {
    body.purchase_requirement.products = getProductSelection(body.purchase_requirement.products);
  }
  if (body.purchase_requirement.collections && body.purchase_requirement.collections.length > 0) {
    body.purchase_requirement.collections = getCollectionSelection(body.purchase_requirement.collections);
  }
  if (body?.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
    body.purchase_requirement.collections = [];
  } else if (body?.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
    body.purchase_requirement.products = [];
  }
  if (body?.purchase_requirement?.type === 'PURCHASE_AMOUNT') {
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_QUANTITY') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
  }

  body.purchase_activity = null;
  body.purchase_activity_period = null;

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
  } else if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
  } else if (body?.user_order_requirement?.type === 'order') {
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  } else body.total_usage_limit = null;
  if (body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  } else body.per_customer_usage_limit = null;

  if (body?.activation_period?.start) {
    body.activation_period.start = dayjs(body.activation_period.start).toISOString();
  } else body.activation_period.start = dayjs(new Date()).toISOString();

  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end) body.activation_period.end = dayjs(body.activation_period.end).toISOString();
    else body.activation_period.end = dayjs(new Date()).toISOString();
  } else body.activation_period.end = null;

  delete body?.has_title;
  delete body.show_discount_max_cap;
  delete body?.application?.discount_type;
  delete body?.application?.discount_amount;
  delete body?.application?.items_from;
  delete body?.application?.show_max_per_order;
  delete body?.purchase_requirement.show_max_amount;
  delete body?.purchase_requirement.show_max_quantity;
  delete body?.purchase_requirement.productType;
  delete body?.purchase_requirement.items_from;
  delete body?.user_order_requirement?.type;
  delete body?.user_order_requirement?.max_order_count_needed;
  delete body?.show_total_usage_limit;
  delete body?.show_per_customer_usage_limit;
  delete body?.activation_period.has_endTime;

  return body;
};

export const discountPurchaseDrivenDataHandler = (values: any) => {
  const body = { ...values };

  if (body?.has_title === false) {
    body.title = null;
  }

  if (body?.show_discount_max_cap === false) {
    body.discount_max_cap = null;
  }

  if (body?.application_type === ApplicationType.Automatic) {
    body.discoverable = false;
  }
  if (body?.purchase_activity?.type === PurchaseActivityType.CouponCode) {
    body.purchase_activity.coupon_usage = {};
    body.purchase_activity.coupon_usage.coupon_id = body?.purchase_activity?.selected_coupon_id;
    body.purchase_activity.min_amount = null;
    body.purchase_activity.min_quantity = null;
  } else if (body?.purchase_activity?.type === PurchaseActivityType.OrderAmount) {
    body.purchase_activity.coupon_usage = null;
    body.purchase_activity.min_quantity = null;
  } else if (body?.purchase_activity?.type === PurchaseActivityType.OrderCount) {
    body.purchase_activity.coupon_usage = null;
    body.purchase_activity.min_amount = null;
  }
  if (body?.purchase_activity_period?.start) {
    body.purchase_activity_period.start = dayjs(body.purchase_activity_period.start).toISOString();
  } else body.purchase_activity_period.start = dayjs(new Date()).toISOString();
  if (body?.purchase_activity_period?.has_endTime) {
    if (body?.purchase_activity_period?.end)
      body.purchase_activity_period.end = dayjs(body.purchase_activity_period.end).toISOString();
    else body.purchase_activity_period.end = dayjs(new Date()).toISOString();
  } else body.purchase_activity_period.end = null;

  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }
  if (body?.purchase_requirement?.productType === 'PURCHASE_CART') {
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  }
  if (body.purchase_requirement.products && body.purchase_requirement.products.length > 0) {
    body.purchase_requirement.products = getProductSelection(body.purchase_requirement.products);
  }
  if (body.purchase_requirement.collections && body.purchase_requirement.collections.length > 0) {
    body.purchase_requirement.collections = getCollectionSelection(body.purchase_requirement.collections);
  }
  if (body?.purchase_requirement.productType === 'PURCHASE_PRODUCTS') {
    body.purchase_requirement.collections = [];
  } else if (body?.purchase_requirement.productType === 'PURCHASE_COLLECTIONS') {
    body.purchase_requirement.products = [];
  }
  if (body?.purchase_requirement?.type === 'NONE') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_AMOUNT') {
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_QUANTITY') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
  }

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
  } else if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
  } else if (body?.user_order_requirement?.type === 'order') {
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  } else body.total_usage_limit = null;
  if (body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  } else body.per_customer_usage_limit = null;

  if (body?.activation_period?.start) {
    body.activation_period.start = dayjs(body.activation_period.start).toISOString();
  } else body.activation_period.start = dayjs(new Date()).toISOString();
  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end) body.activation_period.end = dayjs(body.activation_period.end).toISOString();
    else body.activation_period.end = dayjs(new Date()).toISOString();
  } else body.activation_period.end = null;

  delete body?.has_title;
  delete body.show_discount_max_cap;
  delete body.application.max_per_order;
  delete body.application.min_quantity;
  delete body?.application?.discount_type;
  delete body?.application?.discount_amount;
  delete body?.application?.items_from;
  delete body?.application?.show_max_per_order;
  delete body?.purchase_activity_period?.has_endTime;
  delete body?.purchase_activity?.selected_coupon_id;
  delete body?.purchase_requirement.show_max_amount;
  delete body?.purchase_requirement.show_max_quantity;
  delete body?.purchase_requirement.productType;
  delete body?.purchase_requirement.items_from;
  delete body?.user_order_requirement?.type;
  delete body?.user_order_requirement?.max_order_count_needed;
  delete body?.show_total_usage_limit;
  delete body?.show_per_customer_usage_limit;
  delete body?.activation_period.has_endTime;

  return body;
};
