/* eslint-disable camelcase */
import classNames from 'classnames';
import { Button } from '../../flexyui';
import { Input } from '../../flexyui/Input';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import {
  CalculateLineItem,
  DiscountType,
  useAddLineItemDiscountMutation,
  useDeleteLineItemDiscountMutation,
  useEditLineItemDiscountMutation
} from '../../../graphql';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../flexyui/Dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../flexyui/Select';

type Props = {
  lineItem: CalculateLineItem;
  calculatedOrderId: string;
  setDiscountLineItem: (data: any) => void;
  setCalculatedLineItems: (data: CalculateLineItem[]) => void;
  openApplyDiscount: boolean;
  setOpenApplyDiscount: (data: boolean) => void;
  setOrderEditSummary: any;
};

export const ApplyDiscount: React.FC<Props> = ({
  lineItem,
  calculatedOrderId,
  setDiscountLineItem,
  setCalculatedLineItems,
  openApplyDiscount,
  setOpenApplyDiscount,
  setOrderEditSummary
}) => {
  const [discountType, setDiscountType] = useState(lineItem?.discount?.discount_type || DiscountType.Fixed);
  const [discountAmount, setDiscountAmount] = useState<any>(
    lineItem?.discount?.discount_type === DiscountType.Fixed ? lineItem?.discount?.discount_amount : ''
  );
  const [discountPercentage, setDiscountPercentage] = useState<any>(
    lineItem?.discount?.discount_type === DiscountType.Percentage ? lineItem?.discount?.discount_amount : ''
  );
  const [editReason, setEditReason] = useState<string>(lineItem?.discount?.reason_for_edit || '');

  const [addLineItemDiscount, { loading: addLineItemDiscountLoading }] = useAddLineItemDiscountMutation();
  const [editLineItemDiscount, { loading: editLineItemDiscountLoading }] = useEditLineItemDiscountMutation();
  const [deleteLineItemDiscount, { loading: deleteLineItemDiscountLoading }] = useDeleteLineItemDiscountMutation();

  const handleAddLineItemDiscount = async () => {
    try {
      await addLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          discountType,
          discountValue:
            discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
          reasonForEdit: editReason
        }
      }).then((res) => {
        const responseData = res?.data?.addLineItemDiscount;

        if (responseData) {
          const { calculated_line_items, summary, errors } = responseData;
          if (calculated_line_items) {
            setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
          }
          if (summary) setOrderEditSummary(summary);
          if ((errors && errors?.length) || 0 > 0) {
            errors?.forEach((error) => {
              enqueueSnackbar(error, { variant: 'error' });
            });
          }
        }
      });
    } catch (error) {
      console.error('Error while adding discount:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setOpenApplyDiscount(false);
  };
  const handleEditLineItemDiscount = async () => {
    if (!lineItem?.calculated_discount_application_id) return;

    try {
      await editLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          calculatedDiscountApplicationId: lineItem?.calculated_discount_application_id,
          discountType,
          discountValue:
            discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
          reasonForEdit: editReason
        }
      }).then((res) => {
        const responseData = res?.data?.editLineItemDiscount;

        if (responseData) {
          const { calculated_line_items, summary, errors } = responseData;
          if (calculated_line_items) {
            setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
          }
          if (summary) setOrderEditSummary(summary);
          if ((errors && errors?.length) || 0 > 0) {
            errors?.forEach((error) => {
              enqueueSnackbar(error, { variant: 'error' });
            });
          }
        }
      });
    } catch (error) {
      console.error('Error while editing discount:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setOpenApplyDiscount(false);
  };
  const handleDeleteLineItemDiscount = async () => {
    if (!lineItem?.calculated_discount_application_id) return;

    try {
      await deleteLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedDiscountApplicationId: lineItem?.calculated_discount_application_id
        }
      }).then((res) => {
        const responseData = res?.data?.deleteLineItemDiscount;

        if (responseData) {
          const { calculated_line_items, summary, errors } = responseData;
          if (calculated_line_items) {
            setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
          }
          if (summary) setOrderEditSummary(summary);
          if ((errors && errors?.length) || 0 > 0) {
            errors?.forEach((error) => {
              enqueueSnackbar(error, { variant: 'error' });
            });
          }
        }
      });
    } catch (error) {
      console.error('Error while editing discount:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setOpenApplyDiscount(false);
  };

  const isDisabled = addLineItemDiscountLoading || editLineItemDiscountLoading || deleteLineItemDiscountLoading;

  useEffect(() => {
    if (!openApplyDiscount) setDiscountLineItem(null);
  }, [openApplyDiscount]);

  useEffect(() => {
    if (lineItem) {
      setDiscountType(lineItem?.discount?.discount_type || DiscountType.Fixed);
      setDiscountAmount(
        lineItem?.discount?.discount_type === DiscountType.Fixed ? lineItem?.discount?.discount_amount : ''
      );
      setDiscountPercentage(
        lineItem?.discount?.discount_type === DiscountType.Percentage ? lineItem?.discount?.discount_amount : ''
      );
      setEditReason(lineItem?.discount?.reason_for_edit || '');
    }
  }, [lineItem]);

  return (
    <>
      <Dialog open={openApplyDiscount} onOpenChange={(value: boolean) => setOpenApplyDiscount(value)}>
        <DialogContent className="!gap-0" size="sm">
          <div>
            <DialogHeader>
              <DialogTitle className="pl-4 pr-2.5 py-2.5">
                <div className="flex justify-between items-center">
                  <div className="text-sm flex items-center gap-2 font-semibold">
                    {lineItem?.calculated_discount_application_id ? 'Edit discount of' : 'Apply  discount to'}{' '}
                    {lineItem?.variant_title}
                  </div>
                  <Button size="icon" variant="ghost" onClick={() => setOpenApplyDiscount(false)}>
                    <Unicons.UilTimes className="text-[#2F72FF]" />
                  </Button>
                </div>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="w-full p-4 box-border">
                  <div className="flex justify-between items-center mb-4">
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Discount Type</div>
                      <Select
                        value={discountType}
                        defaultValue={DiscountType.Fixed}
                        onValueChange={(value) => setDiscountType(value as DiscountType)}
                      >
                        <SelectTrigger className="h-10 w-full text-black">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent side="bottom">
                          <SelectItem value={DiscountType.Fixed}>Amount</SelectItem>
                          <SelectItem value={DiscountType.Percentage}>Percentage</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Discount Value</div>
                      {discountType === DiscountType.Fixed ? (
                        <Input
                          type="number"
                          name="discount_amount"
                          className="!m-0 rounded-md text-black"
                          iconPosition="start"
                          icon={<Unicons.UilRupeeSign size={15} className="text-[#2A324C]" />}
                          value={discountAmount}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(value)) {
                              setDiscountAmount(value);
                            }
                          }}
                        />
                      ) : (
                        <Input
                          type="number"
                          name="discount_percentage"
                          className="!m-0 rounded-md text-black"
                          iconPosition="end"
                          icon={<Unicons.UilPercentage size={18} className="text-[#2A324C]" />}
                          value={discountPercentage}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(value)) {
                              setDiscountPercentage(value);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="font-medium mb-1 text-[#2A324C]">Reason for edit</div>
                    <Input
                      type="text"
                      name="edit_reason"
                      className="!m-0 rounded-md text-black"
                      value={editReason}
                      onChange={(e) => setEditReason(e.target.value)}
                    />
                  </div>
                  <div className="text-sm test-[#595f74] mb-2">Your customers can see this reason</div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter className="!justify-between !flex-row-reverse !py-2.5 !px-3">
            <Button
              className={`rounded-md ${(addLineItemDiscountLoading || editLineItemDiscountLoading) && '!bg-primary'}`}
              variant="default"
              size="md"
              onClick={() => {
                if (lineItem?.calculated_discount_application_id) handleEditLineItemDiscount();
                else handleAddLineItemDiscount();
              }}
              disabled={
                isDisabled ||
                (discountType === DiscountType.Fixed
                  ? !(discountAmount.length > 0 && parseFloat(discountAmount) > 0)
                  : !(
                      discountPercentage.length > 0 &&
                      parseFloat(discountPercentage) > 0 &&
                      parseFloat(discountPercentage) <= 100
                    ))
              }
            >
              {addLineItemDiscountLoading || editLineItemDiscountLoading ? (
                <LoadingIcon height={20} className={'animate-spin text-white'} />
              ) : lineItem?.calculated_discount_application_id ? (
                'Save'
              ) : (
                'Apply'
              )}
            </Button>
            {lineItem?.calculated_discount_application_id && (
              <Button
                size="sm"
                variant="destructive"
                disabled={isDisabled}
                onClick={handleDeleteLineItemDiscount}
                className={classNames('!m-0 w-[140px] disabled:bg-muted', {
                  '!bg-destructive': deleteLineItemDiscountLoading
                })}
              >
                {deleteLineItemDiscountLoading ? (
                  <LoadingIcon height={20} className={'animate-spin text-white'} />
                ) : (
                  'Remove discount'
                )}
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
