import React from 'react';
import { Card, Label } from '../../flexyui';
import { PaymentMethodIcons } from '../../../constants/dashboard';

type Props = {
  subtitle: string | undefined;
  description: string | undefined;
  paymentIcon: string;
  isPartialCod?: boolean;
};

const PaymentCard: React.FC<Props> = ({ subtitle, description, paymentIcon, isPartialCod }) => {
  const PaymentIcon: React.FC | string = paymentIcon && PaymentMethodIcons[paymentIcon];

  const getPaymentText = (text: string) =>
    isPartialCod && text?.includes('$pending_amount') ? text.replace(/\$pending_amount/g, '₹2629.95') : text;

  return (
    <div>
      <div>
        <Card className="flex w-full p-3 items-center">
          <Card className="p-[6px] mr-3">
            {typeof PaymentIcon === 'string' ? (
              <img src={PaymentIcon} alt="payment methods" className="w-6 h-6" />
            ) : (
              PaymentIcon && <PaymentIcon />
            )}
          </Card>
          <div className="flex flex-col">
            <Label size={'paragraph'} className="text-[#4B5563]">
              {getPaymentText(subtitle || '')}
            </Label>
            <Label size={'sm'} className="text-[#4B5563]">
              {getPaymentText(description || '')}
            </Label>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PaymentCard;
